import { put, call } from "redux-saga/effects";
import ACTIONS from "../../common/action_constant";
import API from '../../common/api_config'
import axiosT1 from "../../../utils/axios";
import { getToken } from "../../../utils/common";



export function* getAuditLogs(action) {
  try {
    const resp = yield call(
      axiosT1.post,
      API.getAuditLogsUrl,
      action.queryParams
      // {
      //   params: action.queryParams
      // }
    );

      if(action.onSuccess){
        const auditList = resp.data.data.list || []
        action.onSuccess(auditList)
      }

  } catch (e) {
    console.log('e:', e)
  }
}


export function* getAllBranchList(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.getAllBranchListUrl,
      {
        params: action.queryParams
      });
      const allBranchList = resp.data.data.list || []
      yield put({
        type:ACTIONS.AUDIT_SCREEN.SET_ALL_BRANCH_LIST,
        payload: allBranchList
      })
      if(action.onSuccess){
        action.onSuccess(allBranchList)
      }
  } catch (e) {
    console.log('e:', e)
  }
}

export function* downloadAuditLogs(action) {
  try {
    const resp = yield call(
      axiosT1.post,
      API.downloadAuditLogsUrl,
      action.queryParams,
      // {
      //   params: action.queryParams
      // }
    );
      
      if(action.onSuccess){
          const fileUrl = resp.data.data[0].Link 
          action.onSuccess(fileUrl)
        }

  } catch (e) {
    console.log('e:', e)
  }
}

export function* getAllDlNO(action) {
  try {
    const resp = yield call(
      axiosT1.get,
      API.getDriverInfo,
      {
        params: action.queryParams
      }
    );
    let allDlno = resp.data.data || []
    allDlno = allDlno.map((ele)=>{
      const newEle = {
        label:ele.dl_no,
        value:ele.dl_no
      }
      return newEle
    })
    yield put({
      type:ACTIONS.AUDIT_SCREEN.SET_ALL_DL_NO,
      payload: allDlno
    })
  } catch (e) {
    console.log('e:',e)
  }
}

export function* getAllVehicelRegNo(action) {
  try{
    const resp = yield call(
      axiosT1.get,
      API.getTruckInfo,
      {
        params: action.queryParams
      }
    );
    let allVehRegno = resp.data.data || []
    allVehRegno = allVehRegno.map((ele)=>{
      const newEle = {
        label:ele.vehicle_reg_no,
        value:ele.vehicle_reg_no
      }
      return newEle
    })
    yield put({
      type:ACTIONS.AUDIT_SCREEN.SET_ALL_VEH_REG_NO,
      payload: allVehRegno
    })
  } catch (e) {
    console.log('e:',e)
  }
}

export function* getAllSapMaterialCode(action) {
  try{
    const resp = yield call(
      axiosT1.get,
      API.getSapVendorMaterial,
      {
        params: action.queryParams
      }
    );
    let allSapMaterialCode = resp.data.data || []
    allSapMaterialCode = allSapMaterialCode.map((ele)=>{
      const newEle = {
        label:ele.sap_material_code,
        value:ele.sap_material_code
      }
      return newEle
    })
    yield put({
      type:ACTIONS.AUDIT_SCREEN.SET_ALL_SAP_MATERIAL_CODE,
      payload: allSapMaterialCode
    })
  } catch (e) {
    console.log('e:',e)
  }
}

export function* getNetworkChainV1(action) {
  try{
    const resp = yield call(
      axiosT1.get,
      API.getNetworkChainV1,
      {
        params: action.queryParams,
        headers: {
          token: getToken(),
        },
      }
    );
    
    let allBranchList = resp.data.data.list || [];

    yield put({
      type:ACTIONS.AUDIT_SCREEN.SET_ALL_BRANCH_LIST,
      payload: allBranchList
    })
  } catch (e) {
    console.log('e:',e)
  }
}
