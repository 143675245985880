import React , { Fragment } from 'react'
import './index.scss'
import { Link } from 'react-router-dom';
const CookiePopupCard = (props) => {
    
    const {
        handleOnClickPreferences = () => {},
        handleOnClickAcceptCookies = () => {},
    } = props;

    return (
        <Fragment>
            <div className="cookie-card-wrapper">
                <div className="cookie-card-info-box">
                    {/* <div className="cookie-icon">🍪</div> */}
                    <p className="cookie-description">
                    We use essential cookies to make our site work. We do not save any <span className = "fontBold">personal information</span>.
                    With your consent, we may also use non-essential cookies to improve user experience and analyze website traffic.
                    By clicking “Accept,” you agree to our website's cookie use as described in our
                        <Link
                            className="cookie-policy-link"
                            to={'/legal/cookie-policy'}
                        >
                            Cookie Policy
                        </Link>.
                        You can change your cookie settings at any time by clicking “Preferences.”
                    </p>
                    <div className="cookie-action-wrapper">
                        <button className="btn Preferences-btn"
                            onClick={handleOnClickPreferences}
                        >
                            Preferences
                        </button>
                        <button className="btn accept-btn"
                            onClick={handleOnClickAcceptCookies}
                        >
                            Accept
                        </button>
                    </div>
                    {/* <button className="btn customize-btn">Customize settings</button> */}
                </div>
            </div>
        </Fragment>
    )
}

export default CookiePopupCard