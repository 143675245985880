import React, { Component, Fragment } from "react";
import { Provider } from "react-redux";
import { Router, Route, Link, Redirect } from "react-router-dom";
import Home from "./home/home";
import About from "./about/about-loader";
import history from "./history";
import Master from "./master/master";
import Broadcast from "./broadcast/broadcast";
// import Login from "./login/login";
import VendorForm from "./vendorForm/vendorForm";
import { getCookie, setCookie, deleteCookie } from "../utils/cookies";
import CompanyDetails from "./home/company-details/company-details";
import ContractsManagement from "./home/contracts_management/contracts_management";
import UpdatePlantsDepots from "./home/plants-depots/update-plants-depots";
import DiscountStructure from "./dataGovernance/discount-structure/discountStructure";
import LiabilityStructure from "./dataGovernance/liability-structure/liabilityStructure";
import DelayPenaltyStructure from "./dataGovernance/delay-penalty-structure/delayPenaltyStructure";
import LaneMasters from "./dataGovernance/lane-masters/laneMasters";
import CityMasters from "./dataGovernance/city-masters/cityMasters";
import OdaMasters from "./dataGovernance/oda-masters/odaMasters";
import GLMasters from "./dataGovernance/gl-masters/glMasters";
import HubMasters from "./dataGovernance/hub-masters/hubMasters";
import DiscountStructureDPHRates from "./dataGovernance/rates-discount-structure/discountStructureDPHRates";
import VendorsList from "./vendors/vendorsList/vendorsList";
import VolumetricWeightStructure from "./dataGovernance/volumetric-weight-structure/volumetricWeightStructure";
import IdleTimer from "react-idle-timer";
import RateMasters from "./dataGovernance/rate-masters/rate-masters";
import Demurrage from "./dataGovernance/demurrage/demurrages";
import OtherCharges from "./dataGovernance/otherCharges/otherCharges";
import HSDMasters from "./dataGovernance/hsd-master/hsd-master";
import VehicleDriverMaster from "./dataGovernance/vehicle-driver-master/vehicleDriverMaster";
import TATForm from "./dataGovernance/city-masters/tatForm";
import ACTION from "./common/action_constant";
import vendorVerify from "./vendorForm/vendorVerify";
import ParentChildView from "./home/parent-child-location-management/ParentChildView/parentChildView";
import LocationMgmt from "./home/parent-child-location-management/LocationMgmt/locationMgmt";
import UploadLocation from "./home/parent-child-location-management/UploadLocation/uploadLocation";
import ClusterMapping from "./home/parent-child-location-management/ClusterMapping/clusterMapping";
import SapVendorForm from "./home/sap-vendor-form/sapVendorForm";
import NetworkMaster from "./dataGovernance/NetworkMaster/NetworkMaster";
import SAPVendorForm from "./vendors/vendorsList/SapVendorForm";
import SapVendorFormEX from "./vendors/vendorsList/SapVendorFormEX";
import AuditScreen from "./pages/AuditScreen";
import MessagePopUpComponent from '../src/common/components/msg-popup/msg-popup'
import LoaderComponent from "./common/components/loader/loader";
import BannerSettings from "./pages/Banner Settings/index";
import Banner from "./pages/Banner Settings/banner";
import DphAmendment from "./dataGovernance/dph-amendment/dph-amendment";
import DphAmendmentDetails from "./dataGovernance/dph-amendment/dph-amendment-details";
import { APP_VERSION } from "../utils/version";
import  LoginAs  from "./pages/LoginAsScreen";
import Login from "./SignIn/Index"
import CookiePolicy from "./pages/Common/CookiePolicy";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isUserLoggedIn() == true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

function isUserLoggedIn(nextState, replace) {
  const path = window.location.href;
  const isSSOUser = localStorage.getItem('isSSOUser');
  if(isSSOUser && path.includes('token')){
    return true;
  } else{
    if (getCookie("user")) {
      const cookieData = getCookie("user");
      const userData = cookieData;
      if (!userData) {
        return false;
      } else {
        return true;
      }
    }
  }
}



class Root extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount=()=>{
    const { store } = this.props;
    const {dispatch} = store
    const queryString=`?module_id=${6}`;
    dispatch({ 
       type: ACTION.BANNER_SETTING.GET_BROADCAST_MSG, 
       params:queryString,
     });
  }

  onIdle = () => {
    deleteCookie();
    localStorage.clear();
    if (location.pathname !== "/") {
      this.idleTimer.reset();
      location.href = "/";
    }
  };

  
  render() {
    const { store } = this.props;
    console.log('APP_VERSION',APP_VERSION)
    return (
      <IdleTimer
        ref={(ref) => {
          this.idleTimer = ref;
        }}
        onIdle={this.onIdle}
        timeout={1000 * 60 * 15}
      >
        <Provider store={store}>
          <Fragment>
            <>
            <Banner/>
            </>
          <Fragment>   
              {/* <MessagePopUpComponent /> */}
          </Fragment>
          <Router history={history}>
            <div>
              <PrivateRoute path="/home" exact component={Home} />
              <PrivateRoute path="/home/:id/:type" exact component={CompanyDetails}/>
              <PrivateRoute path="/home/:id/:type/:branchId" exact component={UpdatePlantsDepots}/>
              <PrivateRoute path="/bannerSettings" component={BannerSettings} />
              <PrivateRoute path="/broadcast" component={Broadcast} />
              <Route path="/bill" component={About} />
              <Route path="/reports" component={About} />
              <Route path="/about" component={About} />
              <Route path="/vehicle-modification" component={About} />
              <PrivateRoute path="/master" component={Master} />
              
              {/* <Route path="/" exact component={Login} /> */}
              <Route path="/" exact component={Login} />

              <Route path="/cm" component={ContractsManagement} />
              {/* <Route path="/masterDataGovernance" component={DiscountStructure} /> */}
              <Route path="/discountStructure" component={DiscountStructure} />
              <Route path="/liabilityStructure" component={LiabilityStructure}/>
              <Route path="/delayPenaltyStructure" component={DelayPenaltyStructure}/>
              <Route path="/laneMasters" component={LaneMasters} />
              <Route path="/tatMasters" component={CityMasters} />
              <Route path="/NetworkMaster" component={NetworkMaster}/>
              <Route path="/volumetricWeightStructure" component={VolumetricWeightStructure}/>
              <Route path="/odaMasters" component={OdaMasters} />
              <Route path="/glMasters" component={GLMasters} />
              <Route path="/hubMasters" component={HubMasters} />
              <Route path="/discountStructureRates" component={DiscountStructureDPHRates}/>
              <Route path="/ratemasters" component={RateMasters} />
              <Route path="/vendor-list/:isApproval" component={VendorsList} />
              <Route path="/vendor-form/:companyId/:vendorId/:hasCP?" component={VendorForm}/>
              <Route path="/vendor-approver/:companyId/:vendorId/:approverType" component={VendorForm}/>
              <Route path="/vendor-bank-approver/:companyId/:vendorId/:approverType" component={VendorForm}/>
              <Route path="/vendor-headoffice-approver/:companyId/:vendorId/:approverType" component={VendorForm}/>
              <Route path="/external-vendor-approver/:companyId/:vendorId/:userId/:approverType" component={VendorForm}/>
              <Route path="/vendor-read/:companyId/:vendorId/:approverType" component={VendorForm}/>
              <Route path="/vendor-verify/:companyId/:vendorId" component={vendorVerify}/>
              <Route path="/demurrage" component={Demurrage} />
              <Route path="/otherCharges" component={OtherCharges} />
              <Route path="/hsdMaster" component={HSDMasters} />
              <Route path="/vehicleDriverMaster" component={VehicleDriverMaster}/>
              <Route path="/tatapproval/:companyId/:vendorId/" component={TATForm}/>
              <Route path="/locationMgmt" component={LocationMgmt} />
              <Route path="/parentChildView" component={ParentChildView} />
              <Route path="/uploadLocation" component={UploadLocation} />
              <Route path="/clusterMapping" component={ClusterMapping} />
              <Route exact path="/sap-vendor-form/:companyId/:vendorId/:hasCP" component={SapVendorForm} />
              <Route exact path="/sap-vendor-form/:companyId/:vendorId/:hasCP/newForm"  component={SAPVendorForm} />
              <Route exact path="/sap-vendor-form/:companyId/OnboardExworkvendor" component={SapVendorFormEX} />
              <Route exact path="/auditScreen/:companyId/:masterType" component={AuditScreen} />
              <Route exact path="/auditScreen/:companyId/:masterType/:branchId" component={AuditScreen} />
              <Route exact path="/auditScreen/:companyId/:masterType/:branchId/:branchCode" component={AuditScreen} />
              <Route path="/dphAmendment" component={DphAmendment} />
              {/* <Route path="/dphAmendmentDetails/:vendorId" component={DphAmendmentDetails} /> */}
              <Route path="/loginAs/:token"  component={LoginAs} />
              <Route exact path="/legal/cookie-policy" component={CookiePolicy} />
            </div>
          </Router>
          </Fragment>
        </Provider>
      </IdleTimer>
    );
  }
}

export default Root;
