export var cargoType = [{
  label: 'Volumetric',
  value: 0
}, {
  label: 'Non Volumetric',
  value: 1
}];
export var productUnit = [{
  label: 'CM',
  value: 'CM'
}, {
  label: 'IN',
  value: 'IN'
}, {
  label: 'FT',
  value: 'IN'
}, {
  label: 'MM',
  value: 'MM'
}, {
  label: 'MT',
  value: 'MT'
}];
export var physicalState = [{
  label: 'Solid',
  value: 'Solid'
}, {
  label: 'Liquid',
  value: 'Liquid'
}, {
  label: 'Purity',
  value: 'Purity'
}];
export var dplPhysicalState = [{
  label: 'Solid',
  value: 'Solid'
}, {
  label: 'Liquid',
  value: 'Liquid'
}, {
  label: 'Purity',
  value: 'Purity'
}, {
  label: 'Gas',
  value: 'Gas'
}];
export var stackingPermitted = [{
  label: 'Permitted',
  value: 'Permitted'
}, {
  label: 'Not permitted',
  value: 'Not permitted'
}, {
  label: 'NA',
  value: 'NA'
}];
export var companyName = [{
  label: 'Pfizer Ltd',
  value: 1
}, {
  label: 'PPIPL',
  value: 2
}, {
  label: 'Bacardi',
  value: 3
}, {
  label: 'Beam Suntory',
  value: 4
}, {
  label: 'Roquette',
  value: 5
}, {
  label: 'Cargill',
  value: 6
}, {
  label: 'Emami',
  value: 7
}, {
  label: ' Sap Deepak Phenolics',
  value: 8
}];
export var chainType = [{
  label: 'Cold Chain',
  value: 1
}, {
  label: 'Non Cold Chain',
  value: 2
}];
export var uomType = [{
  label: 'Hours',
  value: 'hours'
}, {
  label: 'Days',
  value: 'days'
}];
export var detentionType = [{
  label: 'At Origin',
  value: 'at_origin'
}, {
  label: 'At Destination',
  value: 'at_destination'
}, {
  label: 'Both',
  value: 'both'
}];
export var damagesUOM = [{
  label: 'Amount',
  value: 'amount'
}, {
  label: 'Percentage',
  value: 'percentage'
}];
export var modeType = [{
  label: 'Road',
  value: 1
}, {
  label: 'Train',
  value: 2
}, {
  label: 'Air',
  value: 3
}];
export var loadType = [{
  label: 'FTL',
  value: 1
}, {
  label: 'LCL',
  value: 2
}, {
  label: 'Courier',
  value: 3
}];
export var modeLoadType = [{
  label: 'FTL Road',
  value: 1
}, {
  label: 'FTL Train',
  value: 2
}, {
  label: 'LCL Road',
  value: 3
}, {
  label: 'LCL Train',
  value: 4
}, {
  label: 'Courier Road',
  value: 5
}, {
  label: 'Courier Air',
  value: 6
}];
export var challengePriceWithCounter = [{
  label: 0,
  value: 0
}, {
  label: 1,
  value: 1
}, {
  label: 2,
  value: 2
}, {
  label: 3,
  value: 3
}, {
  label: 'Any',
  value: -1
}];
export var handlingInstruction = [{
  label: 'Hazardous',
  value: 1
}, {
  label: 'Non-Hazardous',
  value: 2
}];
export var typesOfUse = [{
  label: 'Dedicated',
  value: 1
}, {
  label: 'Market',
  value: 2
}];
export var typeOfOperations = [{
  label: 'Short-Haul',
  value: 1
}, {
  label: 'Long-Haul',
  value: 2
}];
export var unloadingMhe = [{
  label: 'Handtrolley',
  value: 1
}, {
  label: 'Forklift',
  value: 2
}];
export var PackagingType = [{
  label: 'Bin',
  value: 1
}, {
  label: 'Trolley',
  value: 2
}];
export var LoadType = [{
  label: 'Club',
  value: 1
}, {
  label: 'Single',
  value: 2
}];
export var BoundType = [{
  label: 'Inbound',
  value: 1
}, {
  label: 'Outbound',
  value: 2
}];
export var companyIds = ["f5862184a9b648b8ae3c8cfc4dbe242d"];
export var ROQUETTE = ['a5dfe89d1723470aa6058fb8f0f10694'];
export function downloadUrl(urlLink) {
  localStorage.setItem('teg-component', true);
  var link = document.createElement('a');
  link.href = urlLink;
  document.body.appendChild(link);
  link.click();
}
export var lrManageType = [{
  label: 'LR',
  value: 1
}, {
  label: 'AWB',
  value: 2
}];
export var WEIGHMENT_PROCESS_TYPES = [{
  value: 1,
  label: "Ricelake"
}, {
  value: 2,
  label: "ABB"
}, {
  value: 3,
  label: "ABB(PGI) and Ricelake"
}, {
  value: 4,
  label: "ABB and Ricelake(PGI)"
}];