import React, { Component, Fragment } from 'react';
import "../add-user/add-user.less";
import InputText from "../../../../common/components/input-text/input-text";
import Select from 'react-select';
import validate from "../../../../../utils/validate";
import CheckBox from '../../../../components/checkbox/checkbox';
import { MODULE_LIST, LR_FOR_PANTALOON_COMPANY_ID, PANTALOONS_COMPANY_IDS, GREENPLY_COMPANY_IDS, isLoreal, 
   isSuperAdmin
} from "../../../../../utils/common";
import Delete from '../../../../../assets/images/delete.svg';
import DeactivateUser from '../../../../components/delete-confirmation/delete-confirmation';

const approverType = [
	{value: 1, label: '30 To 100'},
	{value: 2, label: '100 To 150'},
	{value: 3, label: '150 and Above'}
]

class EditUser extends Component {
   constructor(props) {
      super(props);
      this.state = {
         name: this.props.selectedUserToEdit.user_name,
         mobile: this.props.selectedUserToEdit.user_contact,
         email: this.props.selectedUserToEdit.user_email,
         username: this.props.selectedUserToEdit.sap_username,
         password: this.props.selectedUserToEdit.sap_password,
         roles: [],
         departments: [],
         has_Tk11_access: this.props.selectedUserToEdit.has_Tk11_access,
         token_rejection_approval: this.props.selectedUserToEdit.token_rejection_approval,
         is_hod_user: this.props.selectedUserToEdit.is_hod_user,
         is_indent_admin: this.props.selectedUserToEdit.is_indent_admin,
         is_recheck_approver: this.props.selectedUserToEdit.is_recheck_approver,
         is_weighment_selector: this.props.selectedUserToEdit.is_weighment_selector,
         recheck_approver_type: approverType.filter((obj) => obj.value === this.props.selectedUserToEdit.recheck_approver_type),
         configurable_loi_period: this.props.selectedUserToEdit.configurable_loi_period,
         indentEditFields: (this.props.indentEditFields || []).map(field => {
            return {
            label: field.field_name,
            value: field.field_id
         }}) || [],
         admin_field_list: (this.props.selectedUserToEdit.admin_field_list || []).map(field => {
            return {
            label: field.field_name,
            value: field.field_id
         }}) || [],
         selectedRole: {
            label: this.props.selectedUserToEdit.role_name,
            value: this.props.selectedUserToEdit.role_id
         },
         selectedDepartment: {
            label: this.props.selectedUserToEdit.department_name,
            value: this.props.selectedUserToEdit.department_id
         },
         actionView: this.props.selectedUserToEdit && this.props.selectedUserToEdit.actions && this.props.selectedUserToEdit.actions.map((action) => {
            return {
               value: action.id,
               label: action.name
            }
         }),
         branchView: this.props.selectedUserToEdit && this.props.selectedUserToEdit.branches && this.props.selectedUserToEdit.branches.map((branch) => {
            return {
               value: branch.branch_id,
               label: branch.branch_name,
               branch_code: branch.branch_code
            }
         }),
         transporterView: this.props.selectedUserToEdit && this.props.selectedUserToEdit.transporters && this.props.selectedUserToEdit.transporters.map((obj) => {
            return {
               value: obj.transporter_id,
               label: obj.transporter_name,
            }
         }),
         selectedStateView:this.props.selectedUserToEdit && this.props.selectedUserToEdit.states &&this.props.selectedUserToEdit.states.map((obj) => {
         return {
               value: obj.state_code,
               label: obj.state,
            }
         }),
         states: [],
         actions: [],
         branches: [],
         transporters:[],
         error: "",
         user_password: this.props.selectedUserToEdit.user_password,
         iserror: "",
         errormessage: "",
         modules_access_list: this.props.selectedUserToEdit.modules_access_list || JSON.parse(JSON.stringify(MODULE_LIST)),
         warehouse_list: this.props.selectedUserToEdit.warehouse_list && Array.isArray(this.props.selectedUserToEdit.warehouse_list) && this.props.selectedUserToEdit.warehouse_list || [{ warehouse_id: '', sub_plant: '' }],
         // warehouses: (this.props.warehouseList || []).map(warehouse => ({ label: warehouse, value: warehouse }))
         indentingActions: (props.indentingActions || []).map(iAction => {
				return {
					label: iAction.name,
					value: iAction.action_id
				}
			}),
         user_indent_actions: this.props.selectedUserToEdit.user_indent_actions || [],
         isBranchTransfer: false,
         isPopupOpen: false,
         indentingDashboards: (props.indentingDashboardListing || []).map(dashboards => {
				return {
					label: dashboards.indent_dashboard_name,
					value: dashboards.indent_dashboard_name
				}
			}),
			user_indent_dashboards: this.props.selectedUserToEdit.user_indent_dashboards || [],
         report_access: this.props.selectedUserToEdit.report_access,
         indenting_dashboard: this.props.selectedUserToEdit.indenting_dashboard || [],
         indenting_reports: this.props.selectedUserToEdit.indenting_reports || [],
         indentingAdminMoves: (this.props.indentingAdminMoves || []).map(field => {
            return {
            label: field.name,
            value: field.move_id
         }}) || [],
         indent_admin_user_moves: this.props.selectedUserToEdit.indent_admin_user_moves ? (this.props.selectedUserToEdit.indent_admin_user_moves || []).map(field => {
            return {
            label: field.name,
            value: field.move_id
         }}) : (this.props.indentingAdminMoves || []).map(field => {
            return {
            label: field.name,
            value: field.move_id
         }}) || [],
         allow_multi_session:false,
      }
   }

   componentDidMount() {
      const { selectedUserToEdit } = this.props
      this.setState((prevState) => {
         const updatedState = { ...prevState };
         updatedState.allow_multi_session = (selectedUserToEdit || {}).allow_multi_session
         return updatedState
      })
   }

   onChangeHandler = (value, id, isType) => {
      const result = validate.validationCheck(value, this.state[id], isType);
      const errMsg = validate.legthValidation(id, value);
      this.setState({
         [id]: result,
         errorMsg: errMsg
      })
   }

   onBlurChange = (e) => {
      let errMsg = validate.legthValidation(e.target.id, e.target.value);
      if (errMsg && e.target.value) {
         this.setState({
            iserror: e.target.id,
            errormessage: errMsg
         })
      } else if (this.state.iserror) {
         this.setState({ iserror: '' });
      }
   }

   handleChange = (role) => {
      this.setState({ selectedRole: role });
   }

   handleChangeAction = (action) => {

      this.setState({ actionView: action });
   }
   handleChangeDepartment = (dept) => {
      let tempRoles = [];

      dept.roles && dept.roles.forEach(role => {
         tempRoles.push(
            {
               label: role.name,
               value: role.role_id
            }
         )
      });
      this.setState({ selectedDepartment: dept, roles: tempRoles, selectedRole: tempRoles[0] });
   }

   verifyUser = () => {
      let { username, password, error } = this.state;

      if (username && password) {
         let data = {
            username,
            password
         }
         this.props.verifyUser(data);
         if (error) {
            this.setState({ error: '' });
         }
      } else {
         this.setState({ error: 'Please Enter The Username and Password' });
      }

   }

   populateActions = () => {
      return (
         <div className="actions" >
            <Select isMulti={true} className="select" value={this.state.actionView} onChange={(action) => this.handleChangeAction(action)} options={this.state.actions} />
         </div>
      )
   }

   handleChangeBranch = (branch, isNewBranch) => {
      if(isNewBranch){
         this.setState({ newBranch: branch });
      } else {
         this.setState({ branchView: branch });
      }
   }
   populateBranch = () => {
      return (
         <div className="actions" >
            <Select isMulti={true} className="select" value={this.state.branchView} onChange={(branch) => this.handleChangeBranch(branch)} options={this.state.branches} />
         </div>
      )
   }

   handleChangeTransporter = (transporter, isNewTransporter) => {
      if(isNewTransporter){
         this.setState({ newTransporter: transporter });
      } else {
         this.setState({ transporterView: transporter });
      }
   }

   populateTransporters = () => {
      const { transportList } = this.props
      const { transporters } = this.state
      console.log("transporters", transporters)
      return (
         <div className="actions">
            <Select
               isMulti={true}
               className="select"
               value={this.state.transporterView}
               onChange={(transporter) => this.handleChangeTransporter(transporter)}
               options={transporters}
            />
         </div>
      );
   };

   userBranchTransfer = () => {
      const {newBranch} = this.state;
      let data = {
         "company_id": this.props.company_id,
         "branch_id": newBranch.value,
         "user_email": this.state.email,
         "branch_name": newBranch.label,
      }
      this.props.userBranchTransfer(data);
      this.props.toggleEdit(null);
   }

   deactivateToggle = () => {
      const {isPopupOpen} = this.state;
      this.setState({isPopupOpen: !isPopupOpen});
   }

   deactivateUser = () => {
      let paramObj = {
         "company_id": this.props.company_id,
         "company_type": this.props.company_type,
         "branch_id": this.props.branch_id,
         "user_name": this.state.name,
         "user_email": this.state.email,
         "statuskey": "deactivate"
      }
      this.setState({isPopupOpen: false})
      this.props.deactivateUser(paramObj);
      this.props.toggleEdit(null);
   }

   editUser = () => {
      
      let paramObj = {
         "company_id": this.props.company_id,
         "company_type": this.props.company_type,
         "branch_id": this.props.branch_id,
         "user_name": this.state.name,
         "user_email": this.state.email,
         "user_contact": this.state.mobile,
         "user_password": this.state.user_password,
         "department_id": this.state.selectedDepartment && this.state.selectedDepartment.value,
         "department_name": this.state.selectedDepartment && this.state.selectedDepartment.label,
         "role_id": this.state.selectedRole && this.state.selectedRole.value,
         "role_name": this.state.selectedRole && this.state.selectedRole.label,
         "has_Tk11_access": this.state.has_Tk11_access,
         "token_rejection_approval": this.state.token_rejection_approval,
         "is_hod_user": this.state.is_hod_user,
         "is_indent_admin": this.state.is_indent_admin,
         "is_recheck_approver": this.state.is_recheck_approver,
         "is_weighment_selector": this.state.is_weighment_selector,
			"recheck_approver_type": this.state.recheck_approver_type ? this.state.recheck_approver_type.value : undefined,
         "configurable_loi_period": this.state.configurable_loi_period,
         "modules_access_list": this.state.modules_access_list,
         "admin_field_list": this.state.admin_field_list.map((field) => {
            return {
               field_name: field.label,
               field_id: field.value
            }
         }),
         "actions": this.state.actionView.map((action) => {
            return {
               id: action.value,
               name: action.label
            }
         }),
         "branches": this.state.branchView.map((branch) => {
            return {
               branch_id: branch.value,
               branch_name: branch.label,
               branch_code: branch.branch_code
            }
         }),
         "transporters": (this.state.transporterView||[]).map((transporter) => {
            return {
              transporter_id: transporter.value,
              transporter_name: transporter.label,
            };
          }),
         "sap_username": this.state.username,
         "sap_password": this.state.password,
         "is_sap_verified": this.props.is_verified,
         "user_id": this.props.selectedUserToEdit.user_id,
         "is_admin": this.props.selectedUserToEdit.is_admin,
         "is_user": this.props.selectedUserToEdit.is_user,
         "is_active": this.props.selectedUserToEdit.is_active,
         "warehouse_list": (this.state.warehouse_list || []).filter(wh => wh.warehouse_id && wh.sub_plant).length > 0 && this.state.warehouse_list || undefined,
         "user_indent_actions": this.state.user_indent_actions || [],
         "user_indent_dashboards": this.state.user_indent_dashboards || [],
         "report_access": this.state.report_access,
			"indenting_reports": this.state.indenting_reports,
			"indenting_dashboard": this.state.indenting_dashboard,
         "indent_admin_user_moves": this.state.indent_admin_user_moves.map((field) => {
            return {
               name: field.label,
               move_id: field.value
            }
         }),
         "states":(this.state.selectedStateView || []).map((elm)=>{
            // console.log("elem",elm)
            return {
               state: elm.label,
               state_code: elm.value
            }
         }),
         allow_multi_session:this.state.allow_multi_session,
         update_flag:true,  
         master_type:25,
         previous_data:this.props.selectedUserToEdit
      }
      let errorMsg = "";
      const validationError = {
         "Name": validate.isEmpty(paramObj.user_name),
         "Email": !validate.email(paramObj.user_email),
         "Phone": !validate.numeric(paramObj.user_contact),
         "User Password": validate.isEmpty(paramObj.user_password),
         "Department": validate.isEmpty(paramObj.department_name),
         "Role": validate.isEmpty(paramObj.role_name)
      }
      const validationFields = [];
      Object.keys(validationError).forEach((key) => {
         if (validationError[key]) {
            validationFields.push(key);
         }
      });

      if (validationFields.length) {
         errorMsg = `Please Enter Valid ${validate.join(validationFields, (item, idx) => {
            if (validationFields.length === 1) {
               return "";
            }
            if (idx > 0 && idx < validationFields.length - 1) {
               return ", ";
            } else if (idx === validationFields.length - 1) {
               return " and ";
            }
            return "";
         })}.`;
      }
      let myDiv = document.getElementById('modal');
      if (errorMsg) {
         myDiv.scrollTop = 0;
         this.setState({
            error: errorMsg
         });
         return;
      }

      if (paramObj.actions.length && !(paramObj.actions[paramObj.actions.length - 1]).name) {
         myDiv.scrollTop = 0;
         this.setState({
            error: "Actions cannot be empty, make sure you have selected!"
         });
         return;
      }

      if (!(paramObj.branches[paramObj.branches.length - 1])) {
         myDiv.scrollTop = 0;
         this.setState({
            error: "Branch cannot be empty, make sure you have selected!"
         });
         return;
      }

      this.props.editUser(paramObj);
      this.props.toggleEdit(null);
   }

   componentWillMount() {
      let tempDepartments = [];
      let tempAction = [];
      let tempRoles = [], roles = [], tempBranch = [];
      let tempTransporters=[]
      let tempstateList = []
      this.props.departments && this.props.departments.forEach(dept => {
         tempDepartments.push(
            {
               label: dept.department_name,
               value: dept.department_id,
               roles: dept.roles
            }
         )
      });
      for (let i = 0; i < tempDepartments.length; i++) {
         if (this.state.selectedDepartment.value == tempDepartments[i].value) {
            tempRoles = tempDepartments[i].roles
         }
      }
      tempRoles && tempRoles.forEach(role => {
         roles.push(
            {
               label: role.name,
               value: role.role_id
            }
         )
      });
      this.props.actions && this.props.actions.forEach(action => {
         tempAction.push(
            {
               label: action.action_name,
               value: action.id
            }
         )
      });

      this.props.allPlantBranches && this.props.allPlantBranches.forEach(branch => {
         tempBranch.push(
            {
               label: branch.branch_name,
               value: branch.branch_id,
               branch_code: branch.branch_code
            }
         )
      });

      this.props.transportList && this.props.transportList.forEach(obj => {
         tempTransporters.push(
            {
               value: obj.transporter_id || obj.company_id,
               label: obj.transporter_name || obj.company_name,
            }
         )
      });
      this.props.stateList && this.props.stateList.forEach(obj => {
         tempstateList.push(
            {
               label: obj.name,
               value: obj.code,
            }
         )
      });

      this.setState({
         departments: tempDepartments,
         actions: tempAction,
         roles: roles,
         branches: tempBranch,
         transporters:tempTransporters,
         states:tempstateList
      });
   }

   onSelectAll = () => {
      let { isAllSelected, modules_access_list } = this.state;
      isAllSelected = !isAllSelected;
      modules_access_list.map(val => {
          val.enable = isAllSelected;
      })
      this.setState({ isAllSelected, modules_access_list })
   }
 
   onSelectModule = (index) => {
      let { modules_access_list, isAllSelected } = this.state;
      modules_access_list[index].enable = !modules_access_list[index].enable;
      if(isAllSelected) {
          isAllSelected = false;
      } else {
          isAllSelected = modules_access_list.filter(val => { return val.enable }).length ===  modules_access_list.length; 
          
      }
      this.setState({ modules_access_list,  isAllSelected });
   }

   onSelectTransfer = () => {
      let isBranchTransfer = '';
      isBranchTransfer = !this.state.isBranchTransfer;
      this.setState({isBranchTransfer})
   }

   handleApproverSelection = (type) => {
		this.setState({recheck_approver_type: type})
   }
   
   addWarehouse = () => {
		const { warehouse_list } = this.state;
		warehouse_list.push({
			warehouse_id: '',
			sub_plant: ''
		});
		this.setState({ warehouse_list });
	}

	deleteWarehouse = (index) => {
		const { warehouse_list } = this.state;
		warehouse_list.splice(index, 1);
		this.setState({ warehouse_list });
	}

   onChangeWarehouse = (index, value, id) => {
		const { warehouse_list } = this.state;
		warehouse_list[index][id] = (value || '').toUpperCase();
		this.setState({
			warehouse_list
		});
	}

   handleReportsDashboard = (el, field) => {
		this.setState({
			[field]: el
		})
	}
   
   getTypes = () => {
		const { slabRange, company_id } = this.props;
		let approverTypes = [];
      const isAdaniWilmar = ['999', '872'].includes(company_id);
		if(isAdaniWilmar){
			(slabRange || []).forEach((slab) => {
				approverTypes.push({value: slab.range_id, label: `${slab.lowerlimit} To ${slab.upperlimit}`})
			})
		}
		else {
			approverTypes = approverType;
		}
		return approverTypes
	}

   handleStateChange(state) {
    this.setState({ selectedStateView: state });
   }

   shouldRenderAllowMultiSessionCheckbox = () => {
      const C1 = isSuperAdmin();
      if (C1) {
         return true;
      }
      return false;
   }

   render() {
      const buttonDisable = (this.state.username && this.state.password) ? 'add-button' : 'add-button btn-disabled';
      const isAdaniWilmar = ['999', '872'].includes(this.props.company_id);
      const isPantaloons = LR_FOR_PANTALOON_COMPANY_ID.includes(this.props.company_id) || PANTALOONS_COMPANY_IDS.includes(this.props.company_id);
      const { reportsDashboardsList } = this.props;
      const isGreenPly = GREENPLY_COMPANY_IDS.includes(this.props.company_id);
      const isMovesPresent = (this.state.admin_field_list || []).find((moves) => { return moves.value == 5});
      return (

         <div className="add-user-wrap">
            <div onClick={this.props.toggleEdit} className="overlay"></div>
            <div id="modal" className="modal-popup">
               <div className="pop-heading">
                  Edit User
                    </div>
               <div className="content add-edit-user">
                  <label className="error">{this.state.error}</label>
                  <form className="form-section" autoComplete="nope">
                     <InputText placeholder="Name" id={"name"} label="Name" value={this.state.name} changeHandler={this.onChangeHandler} />
                     <InputText placeholder="Email" id={"email"} label="Email" value={this.state.email} changeHandler={this.onChangeHandler} additionalProps={{ onBlur: this.onBlurChange }} iserror={this.state.iserror} errormessage={this.state.errormessage} />
                     <InputText placeholder="Mobile No" id={"mobile"} label="Mobile No" value={this.state.mobile} changeHandler={this.onChangeHandler} length={'10'} isValidate={'numeric'} additionalProps={{ onBlur: this.onBlurChange }} iserror={this.state.iserror} errormessage={this.state.errormessage} />
                     <InputText placeholder="Password" id={"user_password"} label="password" value={this.state.user_password} changeHandler={this.onChangeHandler} />
                     {this.shouldRenderAllowMultiSessionCheckbox() && <Fragment>
                        <div className="action-holder tk11-dashboard">
                           <CustomCheckBox id="allow_multi_session" selected={this.state.allow_multi_session} toggle={() => {
                              this.setState((prevState)=>{
                                 const updatedState = {...prevState};
                                 updatedState.allow_multi_session = !prevState.allow_multi_session
                                 return updatedState
                              })
                           }} />
                           <div className="title"> Allow Multi Session</div>
                        </div>
                     </Fragment>}
                  </form>
                  <div className="select-container">
                     <div className="select-holder"><span className="sub-head">DEPARTMENTS</span><Select value={this.state.selectedDepartment} onChange={this.handleChangeDepartment} options={this.state.departments} /></div>
                     <pre>        </pre>
                     {isLoreal() && (
                  <div className="action-holder">
                    <span className="sub-head">Region/State</span>
                    <div className="actions">
                      <Select
                        className="select"
                        isMulti={true}
                        options={this.state.states}
                        value={this.state.selectedStateView}
                        onChange={(state) => this.handleStateChange(state)}
                      />
                    </div>
                    </div>
                    )}
                     <div className="action-holder">
                        <span className="sub-head">BRANCHES</span>
                        {this.populateBranch()}
                     </div>
                     {isAdaniWilmar &&
                        <div className="action-holder">
                           <span className="sub-head">Transporters</span>
                           {this.populateTransporters()}
                        </div>
                     }
                     <div className="select-holder"><span className="sub-head">ROLE</span><Select value={this.state.selectedRole} onChange={this.handleChange} options={this.state.roles} /></div>
                     <pre>        </pre>
                  </div>


                  <div className="select-container">
                     <div className="action-holder">
                        <span className="sub-head">ACTIONS</span>
                        {this.populateActions()}
                     </div>

                     {/* <div className = "action-holder tk11-dashboard">
                                <span className="sub-head">TK11 DASHBOARD</span>
                                <CheckBox status={this.state.has_Tk11_access} id={'has_Tk11_access'} handleChange={(e) => {
                                    this.setState({ has_Tk11_access : e.target.checked})
                                }}/>
                            </div> */}

                     <div className="action-holder tk11-dashboard">
                        <CustomCheckBox id="has_Tk11_access" selected={this.state.has_Tk11_access} toggle={() => {
                           this.setState({ has_Tk11_access: !this.state.has_Tk11_access })
                        }} />
                        <div className="title"> TK11 DASHBOARD</div>
                     </div>
                     <div className="action-holder tk11-dashboard">
                        <CustomCheckBox id="token_rejection_approval" selected={this.state.token_rejection_approval} toggle={() => {
                           this.setState({ token_rejection_approval: !this.state.token_rejection_approval })
                        }} />
                        <div className="title">REJECT TOKEN</div>
                     </div>
                     <div className="action-holder tk11-dashboard">
                        <CustomCheckBox id="is_hod_user" selected={this.state.is_hod_user} toggle={() => {
                           this.setState({ is_hod_user: !this.state.is_hod_user })
                        }} />
                        <div className="title">HOD</div>
                     </div>
                  </div>
                  <div className="select-container">
                     <div className="action-holder tk11-dashboard small-actions">
                        <CustomCheckBox id="is_indent_admin" selected={this.state.is_indent_admin} toggle={() => {
                           this.setState({ is_indent_admin: !this.state.is_indent_admin })
                        }} />
                        <div className="title small-title">Admin</div>
                     </div>
                     {this.state.is_indent_admin && <div className="action-holder">
                        <span className="sub-head">INDENTING FIELDS</span>
                        <div className="actions" >
                              <Select isMulti={true} className="select" value={this.state.admin_field_list} onChange={(options) => {
                                 this.setState({ admin_field_list: options });
                              } } options={this.state.indentEditFields} />
                        </div>
                     </div>
                     }
                     {isMovesPresent && isGreenPly && this.state.is_indent_admin && <div className="action-holder">
                        <span className="sub-head">INDENTING MOVES</span>
                        <div className="actions" >
                              <Select isMulti={true} className="select" value={this.state.indent_admin_user_moves} onChange={(options) => {
                                 this.setState({ indent_admin_user_moves: options });
                              } } options={this.state.indentingAdminMoves} />
                        </div>
                     </div>
                     }
                     <div className="action-holder tk11-dashboard small-actions">
								<CustomCheckBox id="is_indent_admin" selected={this.state.configurable_loi_period} toggle={() => {
									this.setState({ configurable_loi_period: !this.state.configurable_loi_period })
								}} />
								<div className="title small-title">LOI Acceptance Period</div>
							</div>
                     <div className="action-holder tk11-dashboard small-actions">
								<CustomCheckBox id="is_recheck_approver" selected={this.state.is_recheck_approver} toggle={() => {
									this.setState({ is_recheck_approver: !this.state.is_recheck_approver })
								}} />
								<div className="title small-title">Recheck Approval</div>
							</div>
                     <div className="action-holder tk11-dashboard small-actions">
								<CustomCheckBox id="is_weighment_selector" selected={this.state.is_weighment_selector} toggle={() => {
									this.setState({ is_weighment_selector: !this.state.is_weighment_selector })
								}} />
								<div className="title small-title">Weighment Selector</div>
							</div>
                  </div>

                  <div className="select-container action-check">
							<div className="action-holder" style={{width: '270px'}}>
								<span className="sub-head">Indenting Actions</span>
								<div className="actions" >
									<Select isMulti className="select" value={(this.state.indentingActions || []).filter(el => this.state.user_indent_actions.includes(el.value))} onChange={(options) => {
										console.log('data', options, (options || []).map(el =>{ return el.value }))
										this.setState({ user_indent_actions: (options || []).map(el =>{ return el.value }) });
									}} options={this.state.indentingActions} />
								</div>
							</div>
                     {(isAdaniWilmar || isPantaloons) &&
                     <div className="action-holder" style={{width: '270px'}}>
								<span className="sub-head">Indenting Dashboards</span>
								<div className="actions" >
									<Select isMulti className="select" value={(this.state.indentingDashboards || []).filter(el => this.state.user_indent_dashboards.includes(el.value))} onChange={(options) => {
										this.setState({ user_indent_dashboards: (options || []).map(el =>{ return el.value }) });
									}} options={this.state.indentingDashboards} />
								</div>
							</div>}
                     {this.state.is_recheck_approver && 
								<div className="action-holder">
									<span className="sub-head">Approver Type</span>
										<div className="actions" >
											<Select className="select" value={this.state.recheck_approver_type} onChange={this.handleApproverSelection} options={this.getTypes()} />
										</div>
									</div>
								}
                     <div className = "action-holder tk11-dashboard">
								<CustomCheckBox id={1} selected={this.state.isBranchTransfer} toggle={this.onSelectTransfer} />
								<div className = "title small-title">Branch Transfer</div>
							</div>
						</div>
                  
                  {this.state.isBranchTransfer &&
                  <Fragment>
                  <div className="select-container action-check">
							<span className="sub-head">Branch Transfer</span>
						</div>
                  <div className="select-container">
                     <div className="select-holder"><span className="sub-head">DEPARTMENTS</span><Select value={this.state.selectedDepartment} onChange={this.handleChangeDepartment} options={this.state.departments} /></div>
                     <pre>        </pre>
                     <div className="action-holder">
                        <span className="sub-head">EXISTING BRANCHES</span>
                        {/* {this.populateBranch()} */}
                        <div className="m-10">{this.props.branch_name}</div>
                     </div>
                     <div className="action-holder">
                        <span className="sub-head">NEW BRANCHES</span>
                        <Select className="select wt-200 mt-5" value={this.state.newBranch} onChange={(branch) => this.handleChangeBranch(branch, true)} options={this.state.branches} />
                     </div>
                     {this.state.newBranch && Object.keys(this.state.newBranch).length > 0 && <button className={'add-button mt-20'} onClick={this.userBranchTransfer}>Transfer</button>}
                  </div>
                  </Fragment>}

                  <div className="select-container action-check">
							<div className="action-holder tk11-dashboard small-actions">
								<CustomCheckBox id="report_access" selected={this.state.report_access} toggle={() => {
									this.setState({ report_access : !this.state.report_access })
								}} />
								<div className="title small-title">Report Access</div>
							</div>
							{this.state.report_access &&
								<>
									<div className="action-holder">
										<span className="sub-head">Reports</span>
										<div className="actions" >
											<Select isMulti={true} className="select" getOptionLabel={(option) => option.name} getOptionValue={(option) => option.id} value={this.state.indenting_reports} onChange={(el) => this.handleReportsDashboard(el, 'indenting_reports')} options={(reportsDashboardsList || {}).reports || []} />
										</div>
									</div>
									<div className="action-holder">
										<span className="sub-head">Dashboards</span>
										<div className="actions" >
											<Select isMulti={true} className="select" getOptionLabel={(option) => option.name} getOptionValue={(option) => option.id} value={this.state.indenting_dashboard} onChange={(el) => this.handleReportsDashboard(el, 'indenting_dashboard')} options={(reportsDashboardsList || {}).dashboards || []} />
										</div>
									</div>
								</> 
							}
						</div>

                  <div className="select-container action-check">
							<span className="sub-head">Module Config.</span>
						</div>
						<div className="select-container action-check">
							{this.state.modules_access_list.length > 0 && <div className = "action-holder tk11-dashboard">
								<CustomCheckBox selected={this.state.isAllSelected} toggle={this.onSelectAll} />
								<div className = "title small-title">All</div>
							</div>}
							{(this.state.modules_access_list || []).map((moduleList, index) => {
								return(
									<div className="action-holder tk11-dashboard" key={`module_${index}`}>
										<CustomCheckBox id={index} selected={moduleList.enable} toggle={this.onSelectModule} />
										<div className="title small-title">{moduleList.module_name}</div>
									</div>)
							})
							}
						</div>

                  {(this.state.warehouse_list || []).map((wh, index) => (
							<Fragment>
								{index === 0 && <div className="select-container action-check ph-10">
									<span className="sub-head">Warehouse Mapping</span>
								</div>}
								<div className="select-container action-check">
									<InputText placeholder="Warehouse" id={"warehouse_id"} label="Warehouse" value={wh.warehouse_id} changeHandler={this.onChangeWarehouse.bind(this, index)} />
									<InputText placeholder="Sub Plant" id={"sub_plant"} label="Sub Plant" value={wh.sub_plant} changeHandler={this.onChangeWarehouse.bind(this, index)} />
									{this.state.warehouse_list.length > 1 && <div className={'delete-icon mt-5'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.deleteWarehouse(index)} ></div>}
									{this.state.warehouse_list.length - 1 === index && <button className={'add-button mt-5'} onClick={this.addWarehouse}>+ Add</button>}
								</div>
							</Fragment>
						))}

                  <div className="select-container action-check">
                     <InputText placeholder="SAP Username" id={"username"} label="SAP Username" value={this.state.username} changeHandler={this.onChangeHandler} />
                     <InputText placeholder="SAP Password" id={"password"} label="SAP Password" value={this.state.password} changeHandler={this.onChangeHandler} />
                     <div className={buttonDisable} onClick={this.verifyUser}>Verifiy User</div>

                  </div>
                  <div className="submit-section">
                     <button className="add-button discard" onClick={this.props.toggleEdit}>Cancel</button>
                     <button className="add-button" onClick={this.editUser}>Save</button>
                     <button className="add-button" style={{padding: '5px'}} onClick={() => this.deactivateToggle(true)}>Deactivate User</button>
                  </div>
               </div>
            </div>
            {this.state.isPopupOpen && <DeactivateUser
               toggleDelete={this.deactivateToggle}
               delete={this.deactivateUser}
               deactivateUser={true}
               />}
         </div>
      );
   }
}

const CustomCheckBox = (props) => {
   return (
      <div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
         {props.selected && <div className="inner-circle"></div>}
      </div>
   )
}

export default EditUser;
