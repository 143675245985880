import React, { Fragment } from 'react';
import './index.scss';
import Select from "react-select";
import Spacer from '../../components/common/Spacer';
import { options } from 'winston-daily-rotate-file';
import { withRouter } from 'react-router-dom';


const FilterSection = (props) => {
  const {
    allBranchList,
    allDlno,
    allVehRegno,
    allSapMaterialCode,
    selectedBranchName,
    selectedBranchCode,
    selectedDlNO,
    selectedVehNo,
    selectedSapMaterialCode,
    selectedMaterialCode,
    handleOnChangeForBranchName,
    handleOnChangeForBranchCode,
    handleOnChangeForDlNo,
    handleOnChangeForVehNo,
    selectedCompanyCategory,
    handleOnChangeforCompanyCategory,
    handleOnChangeCompname,
    selectedCompanyName,
    handleOnChangeForSapMCode,
    handleOnChangeforMaterialCode,
    handleOnChangeForRoleName,
    MaterialCodeListData,
    handleOnChangeForDeptName,
    handleInputChange,
    CompanyList,
    master_type,
    // allData,
    departments,
    roles,
    departmentLIstValue,
    RoleListValue,
    filterRoleData,
    filterDeptData,
    companyDropdownNameInputChange,
    addgetVolumetricDatainfoSearch,
    handleInputChangeForBranchNameOrCode,
    subPlantsData,
    selectedSubPlantsData,
    handleOnChangeSubPlantsData,
    plantsAndDepotInputSearch,
    loadingVendors,
    selectedVendor,
    handleOnChangeLoadingVendor,
    loadingVendorsInputSearch,
    loadingBays,
    selectedBay,
    handleOnChangeLoadingBay,
    loadingBaySearch,
    MFSInputSearch,
    handleOnChangeMFS,
    selectedMfs,
    MfsMasterData,
    WBMasterList,
    selectedWBMaster,
    handleOnChangeWBMaster,
    handleOnChangeWBConversion,
    selectedWBConversion,
    WBConversionList,
    selectedWarehouseStorage,
    handleOnChangeWarehouseStorage,
    warehouseStorageList,
    materialConversionList,
    handleOnChangeMaterialConversion,
    selectedMaterialConversion,
    materialMrpOptions,
    selectedMrpMaterialCode,
    handleOnChangeMaterialCodeSelection,
    userOptions,
    selectedUserOption,
    handleOnChangePlantUserSelection,
    userInputSearch
  } = props;
  
  const Complist  = (CompanyList||[]).map(obj => {
    return { ...obj, label: obj.company_name,value:obj.company_id };
});


const MaterialCodeList  = (MaterialCodeListData||[]).map(obj => {
  return { ...obj, label: obj.material_code,value:obj.material_code };
});

const filterWBMasterList  = (WBMasterList||[]).map(obj => {
  return { ...obj, label: obj.vendor_name,value:obj.vendor_name };
});

const filterWBConversionList  = (WBConversionList||[]).map(obj => {
  return { ...obj, label: obj.Vendor,value:obj.Vendor };
});

const filterWarehouseStorageList  = (warehouseStorageList||[]).map(obj => {
  return { ...obj, label: obj.warehouse_name,value:obj.warehouse_name };
});

const filterMaterialConversionList  = (materialConversionList||[]).map(obj => {
  return { ...obj, label: obj.Material,value:obj.Material };
});

const filterMaterialMrpOptions = (materialMrpOptions||[]).map(obj => {
  return { ...obj, label: obj.material_code,value:obj.material_code };
});

const filterUserOptions = (userOptions||[]).map(obj => {
  return { ...obj, label: obj.user_name,value:obj.user_id };
});
  const options = [
    { value: '1', label: 'Seekers', name: 'Seeker' },
    { value: '2', label: 'Providers', name: 'Provider' },
    { value: '3', label: '3PL', name: '3PL' },
    { value: '4', label: 'Fleet Owner & Broker', name: 'Fleet Owner & Broker' },
    { value: '5', label: 'Fleet Owner', name: 'Fleet Owner' },
    { value: '6', label: 'Broker', name: 'Broker' },
    { value: '7', label: 'Fleet Owner & Transporter', name: 'Fleet Owner & Transporter' },
    // { value: '8', label: 'Aggregators', name: 'Aggregator'},
    { value: '9', label: 'Surveyors', name: 'Surveyor' },
    { value: '10', label: 'Lashers', name: 'Lasher' },
    { value: '21', label: 'Handling Agents', name: 'Handling Agent' },
    { value: '22', label: 'Shipping Liners', name: 'Shipping Liner' },
    { value: '11', label: 'Fleet Owner and Company', name: 'Fleet Owner and Company'},
    { value: '8', label: 'Multi Service', name: 'Multi Service'},
    { value: '24', label: 'Buyer', name: 'Buyer'}
  ];

  const departmentsList  =filterDeptData && filterDeptData.map(obj => {
    return { ...obj, label: obj.department_name,value:obj.department_id };
  });
const rolesList  = filterRoleData && filterRoleData.map(obj => {
  return { ...obj, label: obj.role_name,value:obj.role_id };
});
  return (
    <Fragment>
      <div className='bg-627FE9 flex items-center gap-15 p-12 rounded-md flex-wrap'>
         <Fragment>
          { (master_type == 1 || master_type == 2 || master_type == 7) &&
           <div>
            <label className='labelT1' >Branch Name</label>
            <Spacer spacing = {'3px'} />
            <Select
              className="react-select-container-T1"
              options={allBranchList}
              getOptionLabel={(option) => option.branch_name}
              getOptionValue={(option) => option.branch_id}
              isClearable = {true}
              value={selectedBranchName}
              onChange = {handleOnChangeForBranchName}
              onInputChange={handleInputChangeForBranchNameOrCode}
            />
           </div>
          }
         </Fragment>
         <Fragment>
          { (master_type == 1 || master_type == 2 || master_type == 10 || master_type == 7) &&
           <div>
            <label className='labelT1' >Branch Code</label>
            <Spacer spacing = {'3px'} />
            <Select
             className="react-select-container-T1"
             options={allBranchList}
             getOptionLabel={(option) => option.branch_code}
             getOptionValue={(option) => option.branch_code}
             isClearable = {true}
             value={selectedBranchCode}
             onChange = {handleOnChangeForBranchCode}
             onInputChange={handleInputChangeForBranchNameOrCode}
            />
           </div>
          }
         </Fragment>
         <Fragment>
          {(master_type == 4) &&
           <div>
            <label className='labelT1' >DL Number</label>
            <Spacer spacing = {'3px'} />
            <Select
             className="react-select-container-T1"
             options={allDlno}
             isClearable = {true}
             value={selectedDlNO}
             onChange = {handleOnChangeForDlNo}
             onInputChange={(e)=>{handleInputChange(e)}}
            />
           </div>
          }
          {(master_type == 6) &&
           <div>
            <label className='labelT1' >Department Name</label>
            <Spacer spacing = {'3px'} />
            <Select
             className="react-select-container-T1"
             options={departmentsList}
             isClearable = {true}
             value={departmentLIstValue}
             onChange = {handleOnChangeForDeptName}
            />
           </div>
          }
          {(master_type == 6) &&
           <div>
            <label className='labelT1' >Role Name</label>
            <Spacer spacing = {'3px'} />
            <Select
             className="react-select-container-T1"
             options={rolesList}
             isClearable = {true}
             value={RoleListValue}
            onChange = {handleOnChangeForRoleName}
            />
           </div>
          }
         </Fragment>
         <Fragment>
          {(master_type == 5) &&
          <div>
            <label className='labelT1'>Vehicle Reg Number</label>
            <Spacer spacing = {'3px'} />
            <Select
             className="react-select-container-T1"
             options={allVehRegno}
             isClearable = {true}
             value = {selectedVehNo}
             onChange = {handleOnChangeForVehNo}
             onInputChange={(e)=>{handleInputChange(e)}}
            />
          </div>
          }
          {(master_type == 3) &&
          <div>
           <label className='labelT1'>Company Category</label>
           <Spacer spacing = {'3px'} />  
           <Select className="react-select-container-T1" 
            value={selectedCompanyCategory} 
            onChange={handleOnChangeforCompanyCategory}
            options={options} />
          </div>
          } 
          {(master_type == 3) &&
          <div>
            <label className='labelT1'>Company Name</label>
            <Spacer spacing = {'3px'} />
            <Select
             className="react-select-container-T1"
             options={Complist}
             isClearable = {true}
             value ={selectedCompanyName}
             onChange = {handleOnChangeCompname}
             onInputChange={(searchText)=>(companyDropdownNameInputChange(searchText))}
            //  onInputChange={(e)=>{handleInputChange(e)}}
            />
          </div>
          }
         </Fragment>
         <Fragment>
          {(master_type == 8) && 
          <div>
            <label className='labelT1'>Sap Material Code</label>
            <Spacer spacing = {'3px'} />
            <Select
             className="react-select-container-T1"
             options={allSapMaterialCode}
             isClearable = {true}
             value = {selectedSapMaterialCode}
             onChange = {handleOnChangeForSapMCode}
             onInputChange={(e)=>{handleInputChange(e)}}
            />
          </div>
          }
         </Fragment>
         <Fragment>
          {(master_type == 9) && 
          <div>
            <label className='labelT1'>Material Code</label>
            <Spacer spacing = {'3px'} />
            <Select
             className="react-select-container-T1"
             options={MaterialCodeList}
             isClearable = {true}
             value = {selectedMaterialCode}
             onChange = {handleOnChangeforMaterialCode}
            onInputChange={(searchText)=>(addgetVolumetricDatainfoSearch(searchText))}
            />
          </div>
          }
         </Fragment>
         <Fragment>
          {(master_type == 11) && 
          <div>
            <label className='labelT1'>Sub Plant</label>
            <Spacer spacing = {'3px'} />
            <Select
             className="react-select-container-T1"
             options={subPlantsData}
             isClearable = {true}
             value = {selectedSubPlantsData}
             onChange = {handleOnChangeSubPlantsData}
            onInputChange={(searchText)=>(plantsAndDepotInputSearch(searchText))}
            />
          </div>
          }
         </Fragment>
        <Fragment>
          {(master_type == 12) &&
            <div>
              <label className='labelT1'>Vendor Name</label>
              <Spacer spacing={'3px'} />
              <Select
                className="react-select-container-T1"
                options={loadingVendors}
                isClearable={true}
                value={selectedVendor}
                onChange={handleOnChangeLoadingVendor}
                onInputChange={(searchText) => (loadingVendorsInputSearch(searchText))}
              />
            </div>
          }
        </Fragment>
        <Fragment>
          {(master_type == 13) &&
            <div>
              <label className='labelT1'>Bay Name</label>
              <Spacer spacing={'3px'} />
              <Select
                className="react-select-container-T1"
                options={loadingBays}
                isClearable={true}
                value={selectedBay}
                onChange={handleOnChangeLoadingBay}
                onInputChange={(searchText) => (loadingBaySearch(searchText))}
              />
            </div>
          }
        </Fragment>
        <Fragment>
          {(master_type == 14) &&
            <div>
              <label className='labelT1'>Material code</label>
              <Spacer spacing={'3px'} />
              <Select
                className="react-select-container-T1"
                options={MfsMasterData}
                isClearable={true}
                value={selectedMfs}
                onChange={handleOnChangeMFS}
                onInputChange={(searchText) => (MFSInputSearch(searchText))}
              />
            </div>
          }
        </Fragment>
        <Fragment>
          {(master_type == 15) &&
            <div>
              <label className='labelT1'>Vendor Name</label>
              <Spacer spacing={'3px'} />
              <Select
                className="react-select-container-T1"
                options={filterWBMasterList}
                isClearable={true}
                value={selectedWBMaster}
                onChange={handleOnChangeWBMaster}
                // onInputChange={(searchText) => (MFSInputSearch(searchText))}
              />
            </div>
          }
        </Fragment>
        <Fragment>
          {(master_type == 16) &&
            <div>
              <label className='labelT1'>Vendor Name</label>
              <Spacer spacing={'3px'} />
              <Select
                className="react-select-container-T1"
                options={filterWBConversionList}
                isClearable={true}
                value={selectedWBConversion}
                onChange={handleOnChangeWBConversion}
                // onInputChange={(searchText) => (MFSInputSearch(searchText))}
              />
            </div>
          }
        </Fragment>
        <Fragment>
          {(master_type == 17) &&
            <div>
              <label className='labelT1'>Warehouse Name</label>
              <Spacer spacing={'3px'} />
              <Select
                className="react-select-container-T1"
                options={filterWarehouseStorageList}
                isClearable={true}
                value={selectedWarehouseStorage}
                onChange={handleOnChangeWarehouseStorage}
                // onInputChange={(searchText) => (MFSInputSearch(searchText))}
              />
            </div>
          }
        </Fragment>
        <Fragment>
          {(master_type == 19) &&
            <div>
              <label className='labelT1'>Material</label>
              <Spacer spacing={'3px'} />
              <Select
                className="react-select-container-T1"
                options={filterMaterialConversionList}
                isClearable={true}
                value={selectedMaterialConversion}
                onChange={handleOnChangeMaterialConversion}
              />
            </div>
          }
        </Fragment>
        <Fragment>
          {(master_type == 22) &&
            <div>
              <label className='labelT1'>Material Code</label>
              <Spacer spacing={'3px'} />
              <Select
                className="react-select-container-T1"
                options={filterMaterialMrpOptions}
                isClearable={true}
                value={selectedMrpMaterialCode}
                onChange={handleOnChangeMaterialCodeSelection}
              />
            </div>
          }
        </Fragment>
        <Fragment>
          {(master_type == 25) &&
            <div>
              <label className='labelT1'>User Name</label>
              <Spacer spacing={'3px'} />
              <Select
                className="react-select-container-T1"
                options={filterUserOptions}
                isClearable={true}
                value={selectedUserOption}
                onChange={handleOnChangePlantUserSelection}
                onInputChange={(searchText) => (userInputSearch(searchText))}
              />
            </div>
          }
        </Fragment>
      </div>
    </Fragment>
  )
}

export default withRouter(FilterSection)
