import React, { Component } from 'react';
import "./plants-depots-users.less";
import Checkbox from "../../../components/checkbox/checkbox";
import SearchText from "../../../common/components/search-text/search-text";
import AddUser from "./add-user/add-user";
import EditUser from "./edit-user/edit-user";
import Button from "../../button-classic/button-classic";
import Edit from "../../../../assets/images/edit.svg";
import Delete from "../../../../assets/images/delete.svg";
import DeleteUser from './../../../components/delete-confirmation/delete-confirmation';
import { AWL_COMPANY_IDS, getUserCompanyId, isLoreal } from './../../../../utils/common';
import ACTION from '../../../common/action_constant';
import ACTIONS from '../../../common/action_constant';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import UserStatusConfirmationPopup from './userStatusConfirmationPopup';

class PlantsDepotsUsers extends Component {
   constructor(props) {
      super(props);
      this.state = {
         user: "",
         selectedUserToEdit: {},
         showEditPopUp: false,
         offset: 0,
         transportList:[],
         showUserStatusChange: false,
         userSetToActive:null,
         userStatusChangeData:null,
         showUserUnblockStatusChange:false,
         userUnblockStatusChangeData:null,
         // stateList:[]
      }
   }

   componentDidMount() {
      this.getUsers(null);
      this.props.getActions();
      this.props.getAllBranch(this.props.company_id);
      this.props.getDepartments(this.props.company_id);
      this.props.getIndentEditFields();
      this.props.getWarehouseList();
      this.props.getIndentActions({companyId: this.props.company_id});
      this.props.getIndentingDashoardListing();
      this.getTransporterList()
      this.props.getSlabRange();
      this.props.getIndentMoves();
      if(AWL_COMPANY_IDS.includes(this.props.company_id)) {
         this.props.getReportDashboardsList();
      }
      if(isLoreal()){
      this.props.getState()
      }
   }

   getTransporterList = () => {
      const { dispatch } = this.props;
      // let params = "?type="+"2"+"&limit=10&offset=0";
      const queryParams = {
         type: 2,
         limit: 10,
         offset: 0
      }
      const onSuccess = (data = []) => {
         // console.log('datayy:', data)
         this.setState({ transportList: data })

      }
      dispatch({
         type: ACTION.HOME.GET_TRANSPOTER_LIST,
         queryParams,
         onSuccess
      });
   }

   getUsers = (text) => {
      let data = {
         company_id: this.props.company_id,
         branch_id: this.props.branch_id,
         company_type: this.props.company_type,
         search: text,
         offset: this.state.offset
      }
      this.props.getUserDepots(data);
   };
   submitSearch = () => {
      if (this.state.user != null || this.state.user.length > 0)
         this.getUsers(this.state.user);
   };
   checkBoxChange = (e, id, type, user) => {
      e.preventDefault();
      e.stopPropagation()
      const {showUserStatusChange}=this.state;
      const data = {
         company_type: user.company_type,
         company_id: user.company_id,
         "email":user.email,
         "branch_id": user.branch_id,
         "user_id": user.user_id,
         is_active: e.target.checked,
      }
      this.setState({
         userSetToActive:data.is_active,
         showUserStatusChange: !showUserStatusChange,
         userStatusChangeData:data
      })
   };
   clearFilterOnCancel = () => {
      this.setState({ user: "" }, () => {
         this.getUsers(null);
      });
   };
   previous = () => {
      if (this.state.offset > 0) {
         this.setState(
            {
               offset: this.state.offset - 10
            },
            () => {
               this.getUsers();
            }
         );
      }
   };
   next = () => {
      this.setState(
         {
            offset: this.state.offset + 10
         },
         () => {
            this.getUsers();
         }
      );
   };

   toggleDelete = (user) => {
      this.setState({
         showDelete: !this.state.showDelete,
         user: user
      })
   }

   deleteUser = () => {
      const { user } = this.state;
      // let paramData = {
      //    "company_id": user.company_id,
      //    "company_type": user.company_type,
      //    "branch_id": user.branch_id,
      //    "user_id": user.user_id
      // }
      this.props.deleteUser(user);
      this.getUsers();
      this.toggleDelete();
   }
   changeHandler = (val, id) => {
      this.setState({
         [id]: val
      })
   }
   toggleAdd = () => {
      this.setState({
         showAddComponent: !this.state.showAddComponent
      })
   }

   toggleEdit = (user) => {
      this.setState({
         showEditPopUp: !this.state.showEditPopUp,
         selectedUserToEdit: user
      })
   }

   uploadUserDetails = (e) => {
      let data = {
         company_id: this.props.company_id,
         branch_id: this.props.branch_id,
         company_type: this.props.company_type,
         offset: this.state.offset
      }
      let formData = new FormData();
      formData.append('file', e.target.files[0]);
      this.props.uploadUserDetails(formData, data);
   }
   
   uploadLorealUserDetails = (e) => {
      let data = {
         company_id: this.props.company_id,
         branch_id: this.props.branch_id,
         company_type: this.props.company_type,
         offset: this.state.offset
      }
      let formData = new FormData();
      formData.append('file', e.target.files[0]);
      this.props.uploadLorealUserDetails(formData, data);
   }

   downloadUserDetails = () => {
      this.props.downloadUserDetails();
   }

   downloadLorealUserDetails = () => {
      this.props.downloadLorealUserDetails();
   }

   shouldRenderUserStatusText=()=>{
      const {userSetToActive}=this.state;
      if(!!userSetToActive){
         return 'Are You Sure You Want To Activate The User ?'
      }
      return 'Are You Sure You Want To Dectivate The User ?'
   }

   onConfirmUserStatusChange=()=>{
      const {showUserStatusChange,userStatusChangeData}=this.state;

      this.props.toggleUserStatus(userStatusChangeData);

      this.setState({
         userSetToActive:null,
         showUserStatusChange: !showUserStatusChange,
         userStatusChangeData:null
      })
   }
   onCancelUserStatusChange=()=>{
      const {showUserStatusChange}=this.state;
      this.setState({
         userSetToActive:null,
         showUserStatusChange: !showUserStatusChange,
         userStatusChangeData:null
      }) 
   }
   unblockUser=(e,user)=>{
      e.preventDefault();
      e.stopPropagation()
      const {showUserUnblockStatusChange}=this.state;
      const data = {
         "email":user.email,
         is_active: true,
      }
      this.setState({
         userUnblockChangeData:data,
         showUserUnblockStatusChange:!showUserUnblockStatusChange,
      })
   }

   onConfirmUserUnblockStatusChange=()=>{
      const {showUserUnblockStatusChange,userUnblockChangeData}=this.state;

      this.props.unblockUserStatus(userUnblockChangeData);

      this.setState({
         showUserUnblockStatusChange: !showUserUnblockStatusChange,
         userUnblockChangeData:null
      })
   }

   onCancelUserUnblockStatusChange=()=>{
      const {showUserUnblockStatusChange}=this.state;
      this.setState({
         showUserUnblockStatusChange: !showUserUnblockStatusChange,
         userUnblockChangeData:null
      }) 
   }

   handleOnClickForAuditBtn = () => {
      const {history,branch_id} = this.props;
      const companyId = getUserCompanyId()
      const masterType = 25;
      const navUrl = `/auditScreen/${companyId}/${masterType}/${branch_id}`;
      history.push(navUrl)
     }

   render() {
      const {transportList}=this.state
      return (
         <div className="plants-depots-main-wrap">
            <div className="search-holder">
               <SearchText id="user" value={this.state.user} placeholder="Search Users" changeHandler={this.changeHandler} click={this.submitSearch} canClear={(this.state.user !== '')} handleClearFilter={this.clearFilterOnCancel} />
               <input class="button-classic" type="button" value="+ New User" onClick={this.toggleAdd} />
              {!isLoreal()&&<button className="button-classic" onClick={this.downloadUserDetails}>  Download Template </button>}
               {isLoreal()&&<button className="button-classic" onClick={this.downloadLorealUserDetails}>  Download Template </button>}
               {!isLoreal()&&<button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload </button>}
               {isLoreal()&&<button className="button-classic" onClick={() => {this.uploadExcel.click()}}> Upload </button>}
             {!isLoreal()&&<input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadUserDetails} onClick={(e) => {e.target.value = null }}/>}
             {isLoreal()&&<input type='file' style ={{display :'none'}} ref={(e) => { this.uploadExcel = e ;}} onChange={this.uploadLorealUserDetails} onClick={(e) => {e.target.value = null }}/>}
               <button
                  className="button-classic"
                  onClick={this.handleOnClickForAuditBtn}
               >
                  Audit
               </button>
            </div>
            <div className="heading">
               <div className="head usn">Username</div>
               <div className="head email">Email</div>
               <div className="head phone">Mobile</div>
               <div className="head branch">Department</div>
               <div className="head role">Role</div>
               <div className="head active">
                  Status
               </div>
               <div className="head active">
                  Unblock user
               </div>
               <div className="head actions">Actions</div>
            </div>
            {this.props.userList && this.props.userList.length == 0 &&
					<div className="depot-users-list no-records"> That Is All, Nothing To Display!
                </div>
            }
            {
               this.props.userList && this.props.userList.map((user) => {
                  return (
                     <div className="depot-users-list">
                        <div className="head usn">{user.user_name}</div>
                        <div className="head email">{user.user_email}</div>
                        <div className="head phone">{user.user_contact}</div>
                        <div className="head branch">{user.department_name}</div>
                        <div className="head role">{user.role_name}</div>
                        <div className="head active">
                           <Checkbox type={user.company_type} status={user.is_active} user={user} id={user.user_id} handleChange={this.checkBoxChange} />
                        </div>
                        <div className="head branch">
                           <Button value="Unblock" className="phone" click={(e)=>this.unblockUser(e,user)}/>
                        </div>
                        <div className="head actions phone">
                           <div className="delete-icon" style={{ backgroundImage: `url(${Edit})` }} onClick={() => this.toggleEdit(user)}></div>
                           <div className="delete-icon" style={{ backgroundImage: `url(${Delete})` }} onClick={() => this.toggleDelete(user)}></div>
                        </div>
                     </div>
                  )
               })
            }
            {
               this.state.showAddComponent &&
               <AddUser
                  // getRoles = {this.getRoles} 
                  toggleAdd={this.toggleAdd}
                  roles={this.props.roles}
                  company_id={this.props.company_id}
                  company_type={this.props.company_type}
                  branch_id={this.props.branch_id}
                  verifyUser={this.props.verifyUser}
                  departments={this.props.departments}
                  actions={this.props.actions}
                  addUser={this.props.addUser}
                  is_verified={this.props.is_verified}
                  allPlantBranches={this.props.allPlantBranches}
                  indentEditFields={this.props.indentEditFields}
                  warehouseList={this.props.warehouseList}
                  indentingActions={this.props.indentingActions}
                  indentingDashboardListing={this.props.indentingDashboardListing}
                  reportsDashboardsList={this.props.reportsDashboardsList}
                  slabRange={this.props.slabRange}
                  transportList={transportList}
                  indentingAdminMoves={this.props.indentingAdminMoves}
                  stateList={this.props.stateList}
               />
            }
            {
               this.state.showEditPopUp &&
               <EditUser
                  // getRoles = {this.getRoles} 
                  toggleEdit={this.toggleEdit}
                  roles={this.props.roles}
                  company_id={this.props.company_id}
                  company_type={this.props.company_type}
                  branch_id={this.props.branch_id}
                  branch_name={this.props.branch_name}
                  selectedUserToEdit={this.state.selectedUserToEdit}
                  verifyUser={this.props.verifyUser}
                  departments={this.props.departments}
                  actions={this.props.actions}
                  editUser={this.props.editUser}
                  is_verified={this.props.is_verified}
                  allPlantBranches={this.props.allPlantBranches}
                  indentEditFields={this.props.indentEditFields}
                  warehouseList={this.props.warehouseList}
                  indentingActions={this.props.indentingActions}
                  userBranchTransfer={this.props.userBranchTransfer}
                  deactivateUser={this.props.deactivateUser}
                  indentingDashboardListing={this.props.indentingDashboardListing}
                  reportsDashboardsList={this.props.reportsDashboardsList}
                  slabRange={this.props.slabRange}
                  transportList={transportList}
                  indentingAdminMoves={this.props.indentingAdminMoves}
                  stateList={this.props.stateList}
               />
            }

            {this.state.showDelete &&
               <DeleteUser
                  toggleDelete={this.toggleDelete}
                  delete={this.deleteUser}
               />
            }
            {this.state.showUserStatusChange && 
               <UserStatusConfirmationPopup
                  displayText = {this.shouldRenderUserStatusText()}
                  userStatusChangeData={this.state.userStatusChangeData}
                  onConfirmUserStatusChange={this.onConfirmUserStatusChange}
                  onCancelUserStatusChange={this.onCancelUserStatusChange}
               />
            }
            {this.state.showUserUnblockStatusChange && 
               <UserStatusConfirmationPopup
                  displayText = {"Are you sure you want to unblock the user?"}
                  userStatusChangeData={null}
                  onConfirmUserStatusChange={this.onConfirmUserUnblockStatusChange}
                  onCancelUserStatusChange={this.onCancelUserUnblockStatusChange}
               />
          }

            <div className="submit-section-pagination">
               <div className="next">
                  <Button value="Next" click={this.next} />
               </div>
               <div className="pre">
                  <Button value="Previous" click={this.previous} />
               </div>
            </div>
         </div>
      );
   }
}

export default connect(null,null)(withRouter(PlantsDepotsUsers));
