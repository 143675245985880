import React, { Component, Fragment } from 'react';
import './index.scss';
import HeaderSection from './HeaderSection';
import FilterSection from './FilterSection';
import NavBarSection from './NavBarSection';
import TableSection from './TableSection';
import Spacer from '../../components/common/Spacer'
;
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoaderComponent from '../../common/components/loader/loader';
import ACTIONS from '../../common/action_constant';
import { getCompanyType, getEpochFromDate, getEpochToDate, getUserCompanyId, getUserId } from '../../../utils/common';
import { getCookie } from '../../../utils/cookies';
import { isValueThereThenReturnData } from '../../../utils';
export class AuditScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      auditList :[],
      selectedBranchName:null,
      selectedBranchCode:null,
      selectedDlNO:null,
      selectedVehNo:null,
      selectedSapMaterialCode:null,
      selectedMaterialCode:null,
      selectedRoleName:null,
      selectedDeptName:null,
      fromDate:null,
      toDate:null,
      // dateRangePickerFocused:false,
      isFromDateFocused:false,
      isToDateFocused:false,
      search:"",
      departmentLIstValue:null,
      RoleListValue:null,
      allRolesArray:[],
      filterDeptData:[],
      filterRoleData:[],
      MaterialCodeListData:[],
      offset: 0,
      limit: 10,
      search1:"",
      search2:"",
      subPlantsData : [],
      selectedSubPlantsData:null,
      loadingVendors:[],
      selectedVendor:null,
      loadingBays:[],
      selectedBay:null,
      MfsMasterData:[],
      selectedMfs:null,
      WBMasterList :[],
      selectedWBMaster : null,
      selectedWBConversion : null,
      WBConversionList : [],
      selectedWarehouseStorage : null,
      warehouseStorageList : [],
      selectedMaterialConversion: null,
      materialMrpOptions:[],
      selectedMrpMaterialCode:null,
      userOptions:[],
      selectedUserOption:null
    }
  }

  componentDidMount(){
    const {match} = this.props;
    const masterType = match.params.masterType;
   this.handleGetAuditLogs()
    if ([1,7,10].includes(+masterType)) {
      this.handleGetAllBranchList()
    }
   if(masterType == 4) { this.getDriverInfo() }
   if(masterType == 5) { this.getVehicleInfo() }
   if(masterType == 3) { this.getcompanyinfo() }
   if(masterType == 8) { this.getSapMaterialCode() }
   if(masterType==6){
     this.adduserRole()        
     this.addUserDep()   
   }
   if(masterType == 9) { this.addgetVolumetricDatainfo() }      
   if(masterType == 7) {
    const {match} = this.props;
    const companyId = match.params.companyId
    const branchId = match.params.branchId
    const data = {
        branchId:branchId,
        companyId:companyId 
      }
      this.getActions(data)
      this.getChecklists(data)
      this.getFields(data)
      this.getMoves(data)
      this.getUsers(data)
      this.getDepartmentUserDetails(data)
      this.getIndentingReasons()
   }

   if(+masterType === 2) { 
     this.handleGetNetworkChainV1()
   }
   if(masterType === 11 || masterType === '11'){
    this.fetchSubPlantsData()
   }
   if(+masterType===12){
    this.fetchLoadingVendors()
   }
   if(+masterType===13){
    this.fetchLoadingBays()
   }
   if(+masterType===14){
    this.fetchMFSList()
   }
   if(+masterType===15){
    this.getWeighBridgeMasterListing()
   }
   if(+masterType===16){
    this.getWBConversionAuditData()
   }
   if(+masterType===17){
    this.getWarehouseStorageAuditData()
   }
   if(masterType == 19) { this.getMaterialConversion() }
   if(masterType == 22) { this.getMaterialMrpGridData() }
   if(masterType == 25) {
    this.getPlanstAndDepotsUsers(); 
  }
  }

  getPlanstAndDepotsUsers = () => {
    const {match, dispatch} = this.props;
    const {search} = this.state;
    const companyId = match.params.companyId
    const branchId = match.params.branchId;
    const paramdata = {
      company_id : companyId,
      branch_id : branchId,
      company_type : 1,
      search,
      offset:0
    }
    dispatch({
      type:ACTIONS.PLANTS.GET_DEPOT_USERS, 
      paramdata,
      onSuccess: (response) => {
				this.setState({
          userOptions:response
        });
			}
    });
 };

  componentDidUpdate(prevProps, prevState){
    const {
      selectedBranchName,
      selectedBranchCode,
      selectedDlNO,
      selectedVehNo,
      selectedSapMaterialCode,
      selectedMaterialCode,
      selectedRoleName,
      selectedDeptName,
      fromDate,
      toDate,
      selectedCompanyCategory,
      selectedCompanyName,
      search,
      search1,
      search2,
      search3,
      selectedSubPlantsData,
      selectedVendor,
      loadingVendorSearch,
      selectedBay,
      loadingBaySearch,
      selectedMfs,
      MFSSearch,
      selectedWBMaster,
      selectedWBConversion,
      selectedWarehouseStorage,
      selectedMaterialConversion,
      selectedMrpMaterialCode,
      selectedUserOption
    } = this.state;
    const {match} = this.props;
    const masterType = match.params.masterType;
    const C1 = prevState.selectedBranchName !== selectedBranchName
    const C2 = prevState.selectedBranchCode !== selectedBranchCode
    const C3 = prevState.toDate !== toDate
    const C4 = prevState.fromDate !== fromDate
    const C5 = prevState.selectedDlNO !== selectedDlNO
    const C6 = prevState.selectedVehNo != selectedVehNo
    const C7 = prevState.selectedSapMaterialCode != selectedSapMaterialCode
    const C8 = prevState.search !== search
    const C9 = prevState.selectedCompanyCategory !== selectedCompanyCategory
    const C10 = prevState.selectedCompanyName !== selectedCompanyName
    const C11 = prevState.selectedRoleName !== selectedRoleName
    const C12 = prevState.selectedDeptName !== selectedDeptName
    const C14 = prevState.selectedMaterialCode !== selectedMaterialCode
    const C13 = prevState.search1 !== search1
    const C15 = prevState.search2 !== search2
    const C16 = prevState.search3 !== search3
    const C17 = prevState.selectedSubPlantsData !== selectedSubPlantsData
    const C18 = prevState.selectedVendor !== selectedVendor
    const C19 = prevState.loadingVendorSearch !== loadingVendorSearch
    const C20 = prevState.selectedBay !== selectedBay
    const C21 = prevState.loadingBaySearch !== loadingBaySearch
    const C22 = prevState.selectedMfs !== selectedMfs
    const C23 = prevState.MFSSearch !== MFSSearch 
    const C24 = prevState.selectedWBMaster !== selectedWBMaster 
    const C25 = prevState.selectedWBConversion !== selectedWBConversion 
    const C26 = prevState.selectedWarehouseStorage !== selectedWarehouseStorage
    const C27 = prevState.selectedMaterialConversion !== selectedMaterialConversion
    const C28 = prevState.selectedMrpMaterialCode !== selectedMrpMaterialCode
    const C29 = prevState.selectedUserOption !== selectedUserOption
   if(C1 || C2 || C3 || C4 || C5 || C6 || C7 || C10 || C11 || C12 || C14 ||
      C16 || C17 || C18 || C20 ||C22 || C24 || C25 || C26 || C27 || C28 || C29) {
    this.handleGetAuditLogs();
    if(+masterType === 2) { 
      this.handleGetNetworkChainV1()
    }
   }
   if(C3||C4){
    this.getMaterialMrpGridData()
   }
   if(C8 && !masterType==25) {
    this.getDriverInfo();
    this.getVehicleInfo();
    this.getSapMaterialCode();
  }
  if(C8 && masterType==25){
    this.getPlanstAndDepotsUsers();
  }
   if(C9 || C13) {
    this.getcompanyinfo()
   }
   if(C15){
    this.addgetVolumetricDatainfo()
   }
   if(C19){
    this.fetchLoadingVendors()
   }
   if(C21){
    this.fetchLoadingBays()
   }
   if(C23){
    this.fetchMFSList()
   }
   if(C24){
    this.getWeighBridgeMasterListing()
   }
   if(C25){
    this.getWBConversionAuditData()
   }
   if(C26){
    this.getWarehouseStorageAuditData()
   }
  }

  getActions = (data) => {
    const {dispatch} = this.props;
    dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_ACTIONS, data});
  }

  getChecklists= (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_CHECKLISTS, data});
  }

  getFields = (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_FIELDS, data});
  }
  
  getMoves = (data) => {
    const {dispatch} = this.props;
    dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_MOVES, data});
  }

  getUsers = (data) => {
    const {dispatch} = this.props;
		dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_USERS, data});
  }

  getDepartmentUserDetails = (data) => {
    const { dispatch } = this.props;
    data.companyType = 1;
    dispatch({
      type: ACTIONS.PLANTS.GET_DEPOT_AND_USER_DETAILS,
      data
    })
  }

  getIndentingReasons = () => {
		const {dispatch, match} = this.props;
    const companyId = match.params.companyId
    const data = {companyId : companyId};
    dispatch({ type : ACTIONS.PLANTS.GET_INDENTING_REASONS, data});
  }
  fetchMaterialCodeDependingOnMasterType=(masterType,material_codeForMasterType9,materialCodeForMasterType14,material_codeForMasterType22)=>{
    if(masterType==14){
      return materialCodeForMasterType14;
    }
    if(masterType==22){
      return material_codeForMasterType22;
    }
    if(masterType==9){
      return material_codeForMasterType9;
    }
    return null;
  }

  handleGetAuditLogs =()=>{
    const {dispatch,match} = this.props
    const {
      selectedBranchName, 
      selectedBranchCode,
      selectedDlNO,
      selectedVehNo,
      selectedSapMaterialCode,
      selectedMaterialCode,
      selectedRoleName,
      selectedDeptName,
      selectedCompanyName,
      selectedCompanyCategory,
      selectedSubPlantsData,
      selectedVendor,
      selectedBay,
      selectedMfs,
      selectedWBMaster,
      selectedWBConversion,
      selectedWarehouseStorage,
      selectedMaterialConversion,
      selectedMrpMaterialCode,
      selectedUserOption
    } = this.state
    let {
      fromDate,
      toDate
    } = this.state
    const branchId = !!selectedBranchName?selectedBranchName.branch_id:null
    const branchCode = !!selectedBranchCode?selectedBranchCode.branch_code:null
    const branchIdForMasterType7and2 = !!selectedBranchCode?selectedBranchCode.branch_id:null
    const dl_no = !!selectedDlNO?selectedDlNO.value:null
    const vehicle_reg_no = !!selectedVehNo?selectedVehNo.value:null
    const sap_material_code = !!selectedSapMaterialCode?selectedSapMaterialCode.value:null
    const material_codeForMasterType9 = !!selectedMaterialCode?selectedMaterialCode.value:null
    const subplant_id = !!selectedSubPlantsData?selectedSubPlantsData.subplant_id:null
    const companyType = !!selectedCompanyCategory?selectedCompanyCategory.value:null
    const company_name = !!selectedCompanyName?selectedCompanyName.label:null
    const role_name = !!selectedRoleName?selectedRoleName.label:null
    const department_name = !!selectedDeptName?selectedDeptName.label:null
    const companyIDforMasterType3 = !!selectedCompanyName?selectedCompanyName.value:null
    const vendor_code=!!selectedVendor?selectedVendor.vendor_id:null
    const bay_name=!!selectedBay?selectedBay.bay_name:null
    const materialCodeForMasterType14=!!selectedMfs?selectedMfs.material_code:null
    const vendor_name=!!selectedWBMaster?selectedWBMaster.vendor_name:null
    const vendor=!!selectedWBConversion?selectedWBConversion.Vendor:null
    const warehouse_name =!!selectedWarehouseStorage?selectedWarehouseStorage.warehouse_name:null
    const Material =!!selectedMaterialConversion?selectedMaterialConversion.Material:null
    const material_codeForMasterType22 = !!selectedMrpMaterialCode?selectedMrpMaterialCode.material_code:null
    fromDate = !!fromDate?fromDate.valueOf():null 
    toDate = !!toDate?toDate.valueOf():null
    const companyId = match.params.companyId;
    const masterType = +match.params.masterType;
    const branch_code = isValueThereThenReturnData(match.params.branchCode);
    const user_name = selectedUserOption?selectedUserOption.label:null
    const queryParams = {
      ...(masterType == 3 ? (!!companyIDforMasterType3 && { companyId: companyIDforMasterType3 }) : (!!companyId && { companyId: companyId })),
      ...(!!masterType && {masterType:masterType}),
      ...(([2,7].includes(masterType) && selectedBranchCode) && !!branchIdForMasterType7and2 && { branchId: branchIdForMasterType7and2 }),
      ...(!!branchId && { branchId }),
      ...(masterType != 2 && !!branchCode && { branchCode: branchCode }),
      ...(!!dl_no && { dl_no }),
      ...(!!vehicle_reg_no && { vehicle_reg_no }),
      ...(!!sap_material_code && { sap_material_code }),
      ...(!!this.shouLdSendMaterialCodeForDownloadAuditLog(masterType,material_codeForMasterType9,materialCodeForMasterType14,material_codeForMasterType22) &&
          { 
            material_code: this.fetchMaterialCodeDependingOnMasterType(masterType,material_codeForMasterType9,materialCodeForMasterType14,material_codeForMasterType22) 
          }),
      ...(!!companyType && { companyType }),
      ...(!!company_name && { company_name }),
      ...(!!role_name && { role_name }),
      ...(!!department_name && { department_name }),
      ...(!!fromDate && { fromDate }),
      ...(!!toDate && { toDate }),
      ...(!!vendor_code && { vendor_code }),
      ...(!!bay_name && { bay_name }),
      ...(!!subplant_id && { subplant_id }),
      ...(!!vendor_name && { vendor_name }),
      ...(!!vendor && { vendor }),
      ...(!!warehouse_name && { warehouse_name }),
      ...(!!Material && { Material }),
      ...(!!branch_code && {branch_code}),
      ...(!!user_name&&{user_name})
    };
    const onSuccess = (data = [])=>{
      this.setState((prevState)=>{
        const updatedState = {...prevState}
        updatedState.auditList = data
        updatedState.allRolesArray = data
        return updatedState
      })
    }
    dispatch({
      type:ACTIONS.AUDIT_SCREEN.GET_AUDIT_LOGS,
      queryParams,
      onSuccess
    })
  }

  handleGetAllBranchList =()=>{
    const {dispatch,match} = this.props
    const companyId = match.params.companyId
    const queryParams = {
      companyId,
      companyType:getCompanyType()
    }
    dispatch({
      type:ACTIONS.AUDIT_SCREEN.GET_ALL_BRANCH_LIST,
      queryParams
    })
  }

  handleGetNetworkChainV1 =(qParams={})=>{
    const {dispatch,match} = this.props
    const companyId = match.params.companyId
    const queryParams = {
      companyId,
      limit:50,
      offset:0,
      ...qParams
    }
    dispatch({
      type:ACTIONS.MASTER.LOCATION_NETWORK.GET_NETWORK_CHAIN_V1 ,
      queryParams
    })
  }

  handleInputChangeForBranchNameOrCode = (inputValue, { action }) => {
    const {match} = this.props;
    const masterType = +match.params.masterType;
    console.log('masterType:', masterType)
    if (action === 'input-change') {
      if(masterType === 2) { 
        const qParams = {
          search:inputValue
        }
        this.handleGetNetworkChainV1(qParams)
      }
    }
  };

  addgetVolumetricDatainfo = () => {
    const { dispatch, match } = this.props;
    const { search2,limit,offset} = this.state;
    const companyId = match.params.companyId;
    const queryString = `?company_id=${companyId}&search=${search2}&limit=${limit}&offSet=${offset}`;
    const onSuccess = (data)=>{
      this.setState({MaterialCodeListData:data.data.list})
      }
    dispatch({
      type: ACTIONS.PLANTS.GET_VOLUMETRIC_MASTER,
      queryString,
      onSuccess
    });  
  }

  addgetVolumetricDatainfoSearch =(searchText) => {
    this.setState({search2:searchText})
}

  plantsAndDepotInputSearch = (searchText) => {
    this.setState({ search3: searchText })
  }

  loadingVendorsInputSearch = (searchText) => {
    this.setState({ loadingVendorSearch: searchText })
  }
  loadingBaysInputSearch = (searchText) => {
    this.setState({ loadingBaySearch: searchText })
  }
  MFSInputSearch = (searchText) => {
    this.setState({ MFSSearch: searchText })
  }
  userInputSearch = (searchText) => {
    this.setState({ search: searchText })
  }

  companyDropdownNameInputChange =(searchText) => {
      this.setState({search1:searchText})
  }
  getcompanyinfo = () => {
    const {dispatch,match} = this.props;
    const {selectedCompanyCategory,search1,limit} = this.state;
    const type = !!selectedCompanyCategory ? selectedCompanyCategory.value : null
    let params = "?type=" + type + "&limit="+limit+"&search="+search1;
    dispatch({ type: ACTIONS.HOME.GET_HOME, data: params });
  }

  handleOnClickBackBtn = ()=>{
    const {history} =  this.props;
    history.goBack();
  }
  shouLdSendMaterialCodeForDownloadAuditLog = (masterType,material_codeForMasterType9,materialCodeForMasterType14,material_codeForMasterType22) =>{
    if(masterType==14 && !!materialCodeForMasterType14){
      return true
    }
    if(masterType==22 && !!material_codeForMasterType22){
      return true
    }
    if(masterType==9 && !!material_codeForMasterType9){
      return true
    }
  return false
  }

  handleOnClickDownloadAuditLogsBtn = ()=>{
    const {dispatch,match} = this.props
    const {
      selectedBranchName, 
      selectedBranchCode,
      selectedDlNO,
      selectedVehNo,
      selectedCompanyCategory,
      selectedCompanyName,
      selectedSapMaterialCode,
      selectedMaterialCode,
      selectedRoleName,
      selectedDeptName,
      selectedWBMaster,
      selectedWBConversion,
      selectedVendor,
      selectedBay,
      selectedMfs,
      selectedSubPlantsData,
      selectedWarehouseStorage,
      selectedMaterialConversion,
      selectedMrpMaterialCode,
      selectedUserOption
    } = this.state
    let {
      fromDate,
      toDate
    } = this.state
    const branchId = !!selectedBranchName?selectedBranchName.branch_id:null
    const branchCode = !!selectedBranchCode?selectedBranchCode.branch_code:null
    const branchIdForMasterType7and2 = !!selectedBranchCode?selectedBranchCode.branch_id:null
    const dl_no = !!selectedDlNO?selectedDlNO.value:null
    const vehicle_reg_no = !!selectedVehNo?selectedVehNo.value:null
    const sap_material_code = !!selectedSapMaterialCode?selectedSapMaterialCode.value:null
    const material_codeForMasterType9 = !!selectedMaterialCode?selectedMaterialCode.value:null
    const materialCodeForMasterType14=!!selectedMfs?selectedMfs.material_code:null
    const companyType = !!selectedCompanyCategory?selectedCompanyCategory.value:null
    const company_name = !!selectedCompanyName?selectedCompanyName.label:null
    const role_name = !!selectedRoleName?selectedRoleName.label:null
    const department_name = !!selectedDeptName?selectedDeptName.label:null
    const companyIDforMasterType3 = !!selectedCompanyName?selectedCompanyName.value:null
    const vendor_code=!!selectedVendor?selectedVendor.vendor_id:null
    const bay_name=!!selectedBay?selectedBay.bay_name:null
    const subplant_id = !!selectedSubPlantsData?selectedSubPlantsData.subplant_id:null
    const vendor_name=!!selectedWBMaster?selectedWBMaster.vendor_name:null
    const vendor=!!selectedWBConversion?selectedWBConversion.Vendor:null
    const warehouse_name =!!selectedWarehouseStorage?selectedWarehouseStorage.warehouse_name:null
    const Material =!!selectedMaterialConversion?selectedMaterialConversion.Material:null
    const material_codeForMasterType22 = !!selectedMrpMaterialCode?selectedMrpMaterialCode.material_code:null
    fromDate = !!fromDate?fromDate.valueOf():null 
    toDate = !!toDate?toDate.valueOf():null
    const companyId = match.params.companyId;
    const masterType = +match.params.masterType;
    const branch_code = isValueThereThenReturnData(match.params.branchCode);
    const user_name = selectedUserOption?selectedUserOption.label:null
    const queryParams = {
      ...(masterType == 3 ? (!!companyIDforMasterType3 && { companyId: companyIDforMasterType3 }) : (!!companyId && { companyId: companyId })),
      ...(!!masterType && {masterType:masterType}),
      ...(([2,7].includes(masterType) && selectedBranchCode) && !!branchIdForMasterType7and2 && { branchId: branchIdForMasterType7and2 }),
      ...(!!branchId && { branchId }),
      ...(masterType != 2 && !!branchCode && { branchCode: branchCode }),
      ...(!!dl_no && { dl_no }),
      ...(!!vehicle_reg_no && { vehicle_reg_no }),
      ...(!!sap_material_code && { sap_material_code }),
      ...(!!this.shouLdSendMaterialCodeForDownloadAuditLog(masterType,material_codeForMasterType9,materialCodeForMasterType14,material_codeForMasterType22) &&
          { 
            material_code: this.fetchMaterialCodeDependingOnMasterType(masterType,material_codeForMasterType9,materialCodeForMasterType14,material_codeForMasterType22) 
          }),
      ...(!!companyType && { companyType }),
      ...(!!company_name && { company_name }),
      ...(!!role_name && { role_name }),
      ...(!!department_name && { department_name }),
      ...(!!fromDate && { fromDate }),
      ...(!!toDate && { toDate }),
      ...(!!vendor_code && { vendor_code }),
      ...(!!bay_name && { bay_name }),
      ...(!!subplant_id && { subplant_id }),
      ...(!!vendor_name && { vendor_name }),
      ...(!!vendor && { vendor }),
      ...(!!warehouse_name && { warehouse_name }),
      ...(!!Material && { Material }),
      ...(!!branch_code && {branch_code}),
      ...(!!user_name&&{user_name})
    };
    

    const onSuccess = (fileUrl)=>{
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", true);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    dispatch({
      type:ACTIONS.AUDIT_SCREEN.DOWNLOAD_AUDIT_LOGS,
      queryParams,
      onSuccess
    })

  }

  handleOnChangeForBranchName = (data)=>{
    this.setState((prevState)=>{
    const updatedState =  {...prevState}
    updatedState.selectedBranchName = data
    return updatedState
    })
  }

  handleOnChangeForBranchCode = (data)=>{
    this.setState((prevState)=>{
      const updatedState =  {...prevState}
      updatedState.selectedBranchCode = data
      return updatedState
      })
  }

  handleChange = (selectedOption) => {
		this.setState({ selectedOption }, () => {
			this.callHomeDetails()
		});
	}

  handleOnChangeForDlNo = (data)=>{
    this.setState((prevState)=>{  
      const updatedState = {...prevState}
      updatedState.selectedDlNO = data
      return updatedState
    });
  }

  handleOnChangeForVehNo = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedVehNo = data
      return updatedState
    })
  }

  handleOnChangeforCompanyCategory = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedCompanyCategory = data
      return updatedState
    })
  } 

  handleOnChangeforMaterialCode = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedMaterialCode = data
      return updatedState
    })
  } 

  handleOnChangeSubPlantsData = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedSubPlantsData = data
      return updatedState
    })
  }
  
  handleOnChangeLoadingVendor = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedVendor = data
      return updatedState
    })
  }
  handleOnChangeLoadingBay = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedBay = data
      return updatedState
    })
  }
  handleOnChangeMFS = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedMfs = data
      return updatedState
    })
  }

  handleOnChangeForSapMCode = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedSapMaterialCode = data
      return updatedState
    })
  }

  handleOnChangeForDeptName = (data) => {
  this.setState((prevState) => {
    return {
      ...prevState,
      selectedDeptName: data,
      departmentLIstValue: data
    };
  });
}

  handleOnChangeForRoleName = (data) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        selectedRoleName: data,
        RoleListValue: data
      };
    });
  }

  adduserRole = () => {
    const {dispatch,match} = this.props
		const companyId = match.params.companyId
    const {search} = this.state
    // const queryParams = {
    //   companyId:companyId,
    //   search: search
    // }
    const onSuccess = (data)=>{
    this.setState({filterRoleData:data.data.list})

    }
    dispatch({ 
      type: ACTIONS.HOME.GET_ROLES, 
      data: companyId,
      onSuccess
    })
    // queryParams
  }

  fetchSubPlantsData = () => {
    const {dispatch,match} = this.props
		const companyId = match.params.companyId
		const branchId = match.params.branchId
    const params = {
      companyId : companyId,
      branchId: branchId
    }
    const onSuccess = (data) => {
      const tempData = data.data && data.data.sub_plants.map(obj => {
        return { ...obj, label: obj.name, value : obj.branch_id};
    });
      this.setState({subPlantsData : tempData})
    }
    dispatch({
      type: ACTIONS.PLANTS.GET_SUBPLANTS,
      data : params,
      onSuccess
  })
  }

  fetchLoadingVendors = () => {
    const {dispatch,match} = this.props
    const {loadingVendorSearch}=this.state;
		const companyId = match.params.companyId
		const branchCode = match.params.branchCode
    const params = {
      company_id : companyId,
      branch_code: branchCode,
      search:loadingVendorSearch
    }
    const onSuccess = (data) => {
      const tempData = data.data && data.data.vendors.map(obj => {
        return { ...obj, label: obj.name, value : obj.vendor_id};
    });
      this.setState({loadingVendors : tempData})
    }
    dispatch({
      type: ACTIONS.PLANTS.GET_LOADING_GANG,
      data : params,
      onSuccess
  })
  }
  fetchLoadingBays = () => {
    const {dispatch,match} = this.props
    const {loadingBaySearch}=this.state;
		const companyId = match.params.companyId
		const branchCode = match.params.branchCode
    const params = {
      company_id : companyId,
      branch_code: branchCode,
    }
    if(!!loadingBaySearch){

      params.search=loadingBaySearch
    }
    const onSuccess = (data) => {
      const tempData = data.data && data.data.bays.map(obj => {
        return { ...obj, label: obj.bay_name, value : obj.bay_name};
    });
      this.setState({loadingBays : tempData})
    }
    dispatch({
      type: ACTIONS.PLANTS.GET_LOADING_BAY,
      data : params,
      onSuccess
  })
  }

  fetchMFSList = () => {
    const { dispatch, match } = this.props
    const { MFSSearch } = this.state;
    const companyId = match.params.companyId
    const depotCode = match.params.branchCode

    let queryString = { companyId, depotCode, }
    if (MFSSearch !== undefined) {
      queryString.search = MFSSearch
    }
    queryString = new URLSearchParams(queryString)
    const onSuccess = (data) => {
      const tempData = data.data && data.data.map(obj => {
        return { ...obj, label: obj.material_code, value: obj.material_code };
      });
      this.setState({ MfsMasterData: tempData })
    }
    dispatch({
      type: ACTIONS.PLANTS.GET_MFS_MASTER_DETAILS,
      queryString,
      onSuccess
    })
  }

   addUserDep = () => {
    const {dispatch,match} = this.props
		const companyId = match.params.companyId
    const {search} = this.state
    // const queryParams = {
    //   companyId:companyId,
    //   search: search
    // }
    const onSuccess = (data)=>{
      this.setState({filterDeptData:data.data.list})
    }
     dispatch({ 
      type: ACTIONS.HOME.GET_DEPARTMENTS, 
       data: companyId, 
      onSuccess 
    });
   }
    

  // handleOnFocusedForDateRangePicker = (focused)=>{
  //   this.setState((prevState)=>{
  //     const updatedState =  {...prevState}
  //     updatedState.dateRangePickerFocused = focused
  //     return updatedState
  //     })
  // }

  handleOnFocusedForFromDate = (focused)=>{
    this.setState((prevState)=>{
      const updatedState =  {...prevState}
      updatedState.isFromDateFocused = focused
      return updatedState
      })
  }

  handleOnFocusedForToDate = (focused)=>{
    this.setState((prevState)=>{
      const updatedState =  {...prevState}
      updatedState.isToDateFocused = focused
      return updatedState
      })
  }

  // handleOnChangeForDateRangePicker = (fromDate,toDate)=>{
  //   this.setState((prevState)=>{
  //     const updatedState =  {...prevState}
  //     updatedState.fromDate = fromDate
  //     updatedState.toDate = toDate
  //     return updatedState
  //     })
  // }

  handleOnChangeForFromDate = (fromDate)=>{
    this.setState((prevState)=>{
      const updatedState =  {...prevState}
      updatedState.fromDate = fromDate
      return updatedState
      })
  }

  handleOnChangeForToDate = (toDate)=>{
    this.setState((prevState)=>{
      const updatedState =  {...prevState}
      updatedState.toDate = toDate
      return updatedState
      })
  }

  handleOnChangeCompname = (data)=>{
    this.setState((prevState)=>{  
      const updatedState = {...prevState}
      updatedState.selectedCompanyName = data
      return updatedState
    }
    );
  }

  handleOnClickForClearBtn = ()=>{
    this.setState((prevState)=>{
      const updatedState =  {...prevState}
      updatedState.fromDate = null
      updatedState.toDate = null
      return updatedState
      })
  }
  getDriverInfo = () => {
    const {dispatch,match} = this.props
		const companyId = match.params.companyId
    const {search} = this.state
    const queryParams ={
      company_id:companyId,
      search
    }
		dispatch({ 
      type : ACTIONS.AUDIT_SCREEN.GET_ALL_DL_NO, 
      queryParams
     });
	}
  handleInputChange = (newValue) => {
    this.setState({search:newValue});    
  };

  getVehicleInfo = () => {
    const {dispatch,match} = this.props
		const companyId = match.params.companyId
    const {search} = this.state
    const queryParams = {
      companyId:companyId,
      search
    }
    dispatch({
      type : ACTIONS.AUDIT_SCREEN.GET_ALL_VEH_REG_NO,
      queryParams
    })
  }

  getSapMaterialCode = () => {
    const {dispatch,match} = this.props
    const companyId = match.params.companyId
    const {search} = this.state
    const queryParams = {
      companyId,
      search
    }
    dispatch({
      type : ACTIONS.AUDIT_SCREEN.GET_ALL_SAP_MATERIAL_CODE,
      queryParams
    })
  }

  getWeighBridgeMasterListing = () => {
    const { dispatch, match,plantsDepots} = this.props;
    const branchCode = match.params.branchCode
    dispatch({
      type: ACTIONS.PLANTS.GET_WEIGH_BRIDGE_MASTER_LISTING,
      data: {
        branchcode: branchCode,
        search : ''
      },
      onSuccess : (data) =>{
        this.setState({WBMasterList:data})
      }
    })
  }

  handleOnChangeWBMaster = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedWBMaster = data
      return updatedState
    })
  }


  getWBConversionAuditData = () => {
    const { dispatch, match, plantsDepots } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.GET_WB_CONVERSION,
      data: { 
        company_id: match.params.companyId,
        branch_id: match.params.branchId
      },
      onSuccess : (data) =>{
        this.setState({WBConversionList:data})
      }
    })
  }

  getWarehouseStorageAuditData = () => {
    const { dispatch, match, plantsDepots } = this.props;
    const data = {
      company_id: match.params.companyId,
      branch_code: match.params.branchId,
      offset : 0
    }
    dispatch({ 
      type: ACTIONS.PLANTS.WAREHOUSE_STORAGE_DETAILS, 
      data ,
      onSuccess : (data) =>{
        this.setState({warehouseStorageList:data})
      }
    });
  }

  handleOnChangeWBConversion = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedWBConversion = data
      return updatedState
    })
  }

  handleOnChangeWarehouseStorage = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedWarehouseStorage = data
      return updatedState
    })
  }
  getMaterialConversion() {
    const { dispatch, match } = this.props;
    const param = {
        companyId: match.params.companyId
    }
    dispatch({
        type: ACTIONS.HOME.GET_MATERIAL_CONVERSION,
        param
    })
  }
  handleOnChangeMaterialConversion = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedMaterialConversion = data
      return updatedState
    })
  }

  getMaterialMrpGridData = () => {
    const { dispatch,match } = this.props;
    const {fromDate,toDate,}=this.state
    const queryParams = {
      companyId: match.params.companyId,
      fromDate:getEpochFromDate(fromDate),
      toDate:getEpochFromDate(toDate),
    };
    const onSuccess = (data = []) => {
      this.setState({ materialMrpOptions: data });
    };
    dispatch({
      type: ACTIONS.HOME.GET_MATERIALA_DETAILS,
      queryParams,
      onSuccess,
    });
  };
  handleOnChangeMaterialCodeSelection = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedMrpMaterialCode = data
      return updatedState
    })
  }
  handleOnChangePlantUserSelection = (data) => {
    this.setState((prevState)=>{
      const updatedState = {...prevState}
      updatedState.selectedUserOption = data
      return updatedState
    })
  }

  render() {

    const {
      loader,
      allBranchList,
      allDlno,
      match,
      allVehRegno,
      home,
      allSapMaterialCode,
      indentingActions,
      indentingChecklists,
      indentingFields,
      indentingMoves,
      indentingUsers,
      depotAndUserDetails,
      indentingReasons,
      materialConversionList
    } = this.props;

    const  {
      auditList,
      selectedBranchName,
      selectedBranchCode,
      selectedDlNO,
      selectedVehNo,
      selectedCompanyCategory,
      selectedCompanyName,
      selectedSapMaterialCode,
      selectedMaterialCode,
      selectedRoleName,
      selectedDeptName,
      fromDate,
      toDate,
      // dateRangePickerFocused
      isFromDateFocused,
      isToDateFocused,
      departmentLIstValue,
      RoleListValue,
      filterRoleData,
      filterDeptData,
      MaterialCodeListData,
      subPlantsData,
      selectedSubPlantsData,
      loadingVendors,
      selectedVendor,
      loadingBays,
      selectedBay,
      MfsMasterData,
      selectedMfs,
      WBMasterList,
      selectedWBMaster,
      materialMrpOptions,
      userOptions,
      selectedUserOption
    } = this.state;
    const CompanyList = home.homeDetails && home.homeDetails.length > 0 ? home.homeDetails : [];
    
    const master_type = match.params.masterType
    return (
     <Fragment>
      {!!loader.loaderT1 && <LoaderComponent />}
      {/* {this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />} */}
      <NavBarSection />
      <Spacer spacing = {'40px'} />
      <div className='pageWrapperT1'>
       <HeaderSection   
        handleOnClickBackBtn = {this.handleOnClickBackBtn}
        handleOnClickDownloadAuditLogsBtn = {this.handleOnClickDownloadAuditLogsBtn}
        fromDate = {fromDate}
        toDate = {toDate}
        isFromDateFocused = {isFromDateFocused}
        isToDateFocused = {isToDateFocused}
        // dateRangePickerFocused = {dateRangePickerFocused}
        // handleOnFocusedForDateRangePicker = {this.handleOnFocusedForDateRangePicker}
        // handleOnChangeForDateRangePicker = {this.handleOnChangeForDateRangePicker}
        handleOnFocusedForFromDate = {this.handleOnFocusedForFromDate}
        handleOnFocusedForToDate = {this.handleOnFocusedForToDate}
        handleOnChangeForFromDate = {this.handleOnChangeForFromDate}
        handleOnChangeForToDate = {this.handleOnChangeForToDate}
        handleOnClickForClearBtn = {this.handleOnClickForClearBtn}
       />
       <Spacer spacing = {'6px'} />
       <FilterSection
        allDlno = {allDlno}
        allVehRegno = {allVehRegno}
        allSapMaterialCode = {allSapMaterialCode}
        allBranchList = {allBranchList}
        selectedBranchName = {selectedBranchName}
        selectedBranchCode = {selectedBranchCode}
        selectedDlNO = {selectedDlNO}
        selectedVehNo = {selectedVehNo}
        selectedSapMaterialCode = {selectedSapMaterialCode}
        selectedMaterialCode = {selectedMaterialCode}
        selectedRoleName = {selectedRoleName}
        selectedDeptName = {selectedDeptName}
        handleOnChangeForBranchName = {this.handleOnChangeForBranchName}
        handleOnChangeForBranchCode = {this.handleOnChangeForBranchCode}
        handleOnChangeForDlNo = {this.handleOnChangeForDlNo}
        handleOnChangeForVehNo = {this.handleOnChangeForVehNo}
        handleOnChangeForSapMCode = {this.handleOnChangeForSapMCode}
        handleOnChangeforMaterialCode = {this.handleOnChangeforMaterialCode}
        handleOnChangeForRoleName = {this.handleOnChangeForRoleName}
        handleOnChangeForDeptName = {this.handleOnChangeForDeptName}
        // adduserRole ={this.adduserRole}
        // addUserDep = {this.addUserDep}
        MaterialCodeListData = {MaterialCodeListData}
        handleInputChange = {this.handleInputChange}
        handleOnChangeCompname = {this.handleOnChangeCompname}
        selectedCompanyName ={selectedCompanyName}
        selectedCompanyCategory = {selectedCompanyCategory}
        handleOnChangeforCompanyCategory = {this.handleOnChangeforCompanyCategory}
        companyDropdownNameInputChange ={this.companyDropdownNameInputChange}
        addgetVolumetricDatainfoSearch ={this.addgetVolumetricDatainfoSearch}
        CompanyList={CompanyList }
        master_type = {master_type}
       departmentLIstValue={departmentLIstValue}
       RoleListValue={RoleListValue}
      //  onRoleClear={this.onRoleClear}
      filterDeptData={filterDeptData}
      filterRoleData={filterRoleData}
       handleInputChangeForBranchNameOrCode={this.handleInputChangeForBranchNameOrCode}
       subPlantsData={subPlantsData}
       selectedSubPlantsData={selectedSubPlantsData}
       handleOnChangeSubPlantsData={this.handleOnChangeSubPlantsData}
       plantsAndDepotInputSearch={this.plantsAndDepotInputSearch}
       loadingVendors={loadingVendors}
       selectedVendor={selectedVendor}
       handleOnChangeLoadingVendor={this.handleOnChangeLoadingVendor}
       loadingVendorsInputSearch={this.loadingVendorsInputSearch}
       loadingBays={loadingBays}
       selectedBay={selectedBay}
       handleOnChangeLoadingBay={this.handleOnChangeLoadingBay}
       loadingBaySearch={this.loadingBaysInputSearch}
       MfsMasterData={MfsMasterData}
       selectedMfs={selectedMfs}
       handleOnChangeMFS={this.handleOnChangeMFS}
       MFSInputSearch={this.MFSInputSearch}
       WBMasterList={WBMasterList}
       selectedWBMaster={selectedWBMaster}
       handleOnChangeWBMaster={this.handleOnChangeWBMaster}
       handleOnChangeWBConversion={this.handleOnChangeWBConversion}
       WBConversionList={this.state.WBConversionList}
       selectedWBConversion={this.state.selectedWBConversion}
       handleOnChangeWarehouseStorage = {this.handleOnChangeWarehouseStorage}
       warehouseStorageList = {this.state.warehouseStorageList}
       selectedWarehouseStorage = {this.state.selectedWarehouseStorage}
       materialConversionList={materialConversionList}
       selectedMaterialConversion = {this.state.selectedMaterialConversion}
       handleOnChangeMaterialConversion = {this.handleOnChangeMaterialConversion}
       materialMrpOptions = { materialMrpOptions }
       selectedMrpMaterialCode = { this.state.selectedMrpMaterialCode }
       handleOnChangeMaterialCodeSelection = {this.handleOnChangeMaterialCodeSelection}
       userOptions={userOptions}
       selectedUserOption={selectedUserOption}
       handleOnChangePlantUserSelection={this.handleOnChangePlantUserSelection}
       userInputSearch={this.userInputSearch}
     />
       <Spacer spacing = {'8px'} />
       <TableSection 
        auditList = {auditList}
        indentingActions = {indentingActions}
        indentingChecklists = {indentingChecklists}
        indentingFields = {indentingFields}
        indentingMoves = {indentingMoves}
        indentingUsers = {indentingUsers}
        depotAndUserDetails = {depotAndUserDetails}
        indentingReasons = {indentingReasons}
       /> 
     </div>
     </Fragment>
    )
  }
}


const mapStateToProps = state => {
	console.log('state:', state)
	return {
    home:state.home,
		loader: state.loader,
    allBranchList : state.auditScreenReducer.allBranchList || [],
    allDlno : state.auditScreenReducer.allDlno || [],
    allVehRegno : state.auditScreenReducer.allVehRegno || [],
    allSapMaterialCode : state.auditScreenReducer.allSapMaterialCode || [],
    indentingActions: state.plantsDepots.indentingActions||[],
    indentingChecklists: state.plantsDepots.indentingChecklists || [],
    indentingFields: state.plantsDepots.indentingFields || [],
    indentingMoves: state.plantsDepots.indentingMoves || [],
    indentingUsers: state.plantsDepots.indentingUsers || [],
    depotAndUserDetails: state.plantsDepots.depotAndUserDetails || [],
    indentingReasons: state.plantsDepots.indentingReasons || [],
    materialConversionList : state.home.materialConversion || [],
	};
};

export default connect(mapStateToProps)(withRouter(AuditScreen));
