export const essential_website_cookies = [
  {
    "name": "teg-accounts-company-type",
    "purpose": "Stores the user's company type for account authentication",
    "lifespan": "Deleted after user logout",
    type: "",
    service: "",
    provider: "",
  },
  {
    "name": "teg-accounts-companyId",
    "purpose": "Helps identify and associate the user with their company account",
    "lifespan": "Deleted after user logout"
  },
  {
    "name": "teg-accounts-session-token",
    "purpose": "Maintains session authentication for secure access",
    "lifespan": "Deleted after user logout"
  },
  {
    "name": "teg-company-type",
    "purpose": "Stores the user's company type for account authentication",
    "lifespan": "Deleted after user logout"
  },
  {
    "name": "teg-session-token",
    "purpose": "Ensures secure session management and prevents unauthorized access",
    "lifespan": "Deleted after user logout"
  },
  {
    "name": "teg-settings-visible",
    "purpose": "Stores user preferences for displaying account settings",
    "lifespan": "7 month"
  },
  {
    "name": "teg-user",
    "purpose": "Stores authenticated user details for a personalized experience",
    "lifespan": "Deleted after user logout"
  },
  {
    "name": "tracking-company-type",
    "purpose": "Stores the user's company type for account authentication",
    "lifespan": "Deleted after user logout"
  },
  {
    "name": "tracking-session-token",
    "purpose": "Used for tracking active sessions and preventing fraudulent access",
    "lifespan": "Deleted after user logout"
  },
  {
    "name": "user",
    "purpose": "Identifies the logged-in user to maintain authentication state",
    "lifespan": "Deleted after user logout"
  }
]


export const performance_and_functionality_cookies = [

]

export const analytics_and_customization_cookies = [

]

export const advertising_cookies = [

]