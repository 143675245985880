import _slicedToArray from "/var/lib/jenkins/workspace/S3/Prod-admin-s3/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) { n[e] = r[e]; } return n; }
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
function deleteCookie(cname) {
  document.cookie = cname + "=;path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC";
}
var getCookieV3 = function getCookieV3(name) {
  var cookies = document.cookie.split("; ");
  var _iterator = _createForOfIteratorHelper(cookies),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var cookie = _step.value;
      var _cookie$split = cookie.split("="),
        _cookie$split2 = _slicedToArray(_cookie$split, 2),
        key = _cookie$split2[0],
        value = _cookie$split2[1];
      if (key === name) {
        return JSON.parse(decodeURIComponent(value)); // Convert string back to object
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return null;
};
var setCookieV3 = function setCookieV3(cname, cvalue, exdays, domain) {
  if (!domain) {
    domain = window.location.hostname.includes("localhost") ? "" : window.location.hostname;
  } else if (window.location.hostname.includes("localhost")) {
    domain = "";
  }
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=".concat(d.toUTCString());
  var cookieString = "".concat(cname, "=").concat(encodeURIComponent(cvalue), "; ").concat(expires, "; path=/");
  if (domain) {
    cookieString += "; domain=".concat(domain);
  }
  // Optional: Secure cookies on HTTPS
  if (window.location.protocol === "https:") {
    cookieString += "; Secure; SameSite=Lax";
  }
  document.cookie = cookieString;
};
export { getCookie, setCookie, deleteCookie, getCookieV3, setCookieV3 };